const analyticsVendingMachines = {
  analyticsList: [
    {
      id: "overAll",
      title: "OVERALL ANALYTICS",
      data: {
        day: {
          totalIncidents: "278",
          inventoryLevel: "23%",
          avgTemparature: "33%",
          totalSales: "90",
          operationalAlert: "270",
          inventoryLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          inventoryBreakdownDataVending: [
            {
              title: "FOOD & SNACKS",
              value: 40,
            },
            {
              title: "DRINKS",
              value: 60,
            },
          ],
          avgTemparatureData: [
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          salesData: [
            {
              metricName: "foodSnacks",
              percentage: "40%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
              ],
            },
            {
              metricName: "drinks",
              percentage: "60%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 17,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
          operationalAlertsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          incidentsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 96,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
        },
        weekly: {
          totalIncidents: "1278",
          inventoryLevel: "40%",
          avgTemparature: "30%",
          totalSales: "570",
          operationalAlert: "1870",
          inventoryLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          inventoryBreakdownDataVending: [
            {
              title: "FOOD & SNACKS",
              value: 60,
            },
            {
              title: "DRINKS",
              value: 40,
            },
          ],
          avgTemparatureData: [
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          salesData: [
            {
              metricName: "foodSnacks",
              percentage: "60%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 75.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 69.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 72.0,
                },
              ],
            },
            {
              metricName: "drinks",
              percentage: "40%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 55,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
              ],
            },
          ],
          operationalAlertsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 15.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          incidentsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
        },
        monthly: {
          totalIncidents: "6278",
          inventoryLevel: "50%",
          avgTemparature: "50%",
          totalSales: "1270",
          operationalAlert: "3170",
          inventoryLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 96,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          inventoryBreakdownDataVending: [
            {
              title: "FOOD & SNACKS",
              value: 75,
            },
            {
              title: "DRINKS",
              value: 25,
            },
          ],
          avgTemparatureData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          salesData: [
            {
              metricName: "foodSnacks",
              percentage: "75%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "drinks",
              percentage: "25%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
          operationalAlertsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          incidentsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
        },
        yearly: {
          totalIncidents: "51278",
          inventoryLevel: "45%",
          avgTemparature: "65%",
          totalSales: "10170",
          operationalAlert: "23470",
          inventoryLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          inventoryBreakdownDataVending: [
            {
              title: "FOOD & SNACKS",
              value: 70,
            },
            {
              title: "DRINKS",
              value: 30,
            },
          ],
          avgTemparatureData: [
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          salesData: [
            {
              metricName: "foodSnacks",
              percentage: "70%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "drinks",
              percentage: "30%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 56,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
          operationalAlertsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 96,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          incidentsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
        },
      },
    },
    {
      id: "vendingMachine#01",
      title: "Vending Machine #01",

      data: {
        day: {
          totalIncidents: "778",
          inventoryLevel: "40%",
          avgTemparature: "23%",
          totalSales: "313",
          operationalAlert: "570",
          inventoryLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          inventoryBreakdownDataVending: [
            {
              title: "FOOD & SNACKS",
              value: 65,
            },
            {
              title: "DRINKS",
              value: 35,
            },
          ],
          avgTemparatureData: [
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          salesData: [
            {
              metricName: "foodSnacks",
              percentage: "65%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "drinks",
              percentage: "65%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
          operationalAlertsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 68,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          incidentsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 67,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 68,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
        },
        weekly: {
          totalIncidents: "2378",
          inventoryLevel: "43%",
          avgTemparature: "46%",
          totalSales: "1170",
          operationalAlert: "1170",
          inventoryLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 60,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          inventoryBreakdownDataVending: [
            {
              title: "FOOD & SNACKS",
              value: 63,
            },
            {
              title: "DRINKS",
              value: 37,
            },
          ],
          avgTemparatureData: [
            {
              node: "2022-10-11T11:00:00",
              count: 63,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          salesData: [
            {
              metricName: "foodSnacks",
              percentage: "63%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
              ],
            },
            {
              metricName: "drinks",
              percentage: "37%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
              ],
            },
          ],
          operationalAlertsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          incidentsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 60,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
        },
        monthly: {
          totalIncidents: "6278",
          inventoryLevel: "51%",
          avgTemparature: "54%",
          totalSales: "3170",
          operationalAlert: "3270",
          inventoryLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 71.0,
            },
          ],
          inventoryBreakdownDataVending: [
            {
              title: "FOOD & SNACKS",
              value: 62,
            },
            {
              title: "DRINKS",
              value: 38,
            },
          ],
          avgTemparatureData: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 63,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          salesData: [
            {
              metricName: "foodSnacks",
              percentage: "62%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "drinks",
              percentage: "62%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
          operationalAlertsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          incidentsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 65,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
        },
        yearly: {
          totalIncidents: "61278",
          inventoryLevel: "50%",
          avgTemparature: "60%",
          totalSales: "14370",
          operationalAlert: "20170",
          inventoryLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 63,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 75.0,
            },
          ],
          inventoryBreakdownDataVending: [
            {
              title: "FOOD & SNACKS",
              value: 60,
            },
            {
              title: "DRINKS",
              value: 40,
            },
          ],
          avgTemparatureData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          salesData: [
            {
              metricName: "foodSnacks",
              percentage: "60%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "drinks",
              percentage: "40%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
              ],
            },
          ],
          operationalAlertsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 27.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          incidentsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
        },
      },
    },
    {
      id: "vendingMachine#02",
      title: "Vending Machine #02",
      data: {
        day: {
          totalIncidents: "778",
          inventoryLevel: "31%",
          avgTemparature: "29%",
          totalSales: "470",
          operationalAlert: "670",
          inventoryLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          inventoryBreakdownDataVending: [
            {
              title: "FOOD & SNACKS",
              value: 58,
            },
            {
              title: "DRINKS",
              value: 42,
            },
          ],
          avgTemparatureData: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 71.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          salesData: [
            {
              metricName: "foodSnacks",
              percentage: "58%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "drinks",
              percentage: "42%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
          operationalAlertsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          incidentsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 71.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
        },
        weekly: {
          totalIncidents: "5278",
          inventoryLevel: "43%",
          avgTemparature: "52%",
          totalSales: "1470",
          operationalAlert: "2170",
          inventoryLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 35.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          inventoryBreakdownDataVending: [
            {
              title: "FOOD & SNACKS",
              value: 59,
            },
            {
              title: "DRINKS",
              value: 41,
            },
          ],
          avgTemparatureData: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          salesData: [
            {
              metricName: "foodSnacks",
              percentage: "59%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
              ],
            },
            {
              metricName: "drinks",
              percentage: "41%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
              ],
            },
          ],
          operationalAlertsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          incidentsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
        },
        monthly: {
          totalIncidents: "17278",
          inventoryLevel: "56%",
          avgTemparature: "67%",
          totalSales: "2070",
          operationalAlert: "3170",
          inventoryLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          inventoryBreakdownDataVending: [
            {
              title: "FOOD & SNACKS",
              value: 57,
            },
            {
              title: "DRINKS",
              value: 43,
            },
          ],
          avgTemparatureData: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 60,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          salesData: [
            {
              metricName: "foodSnacks",
              percentage: "57%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "drinks",
              percentage: "43%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
              ],
            },
          ],
          operationalAlertsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          incidentsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 63,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
        },
        yearly: {
          totalIncidents: "67278",
          inventoryLevel: "47%",
          avgTemparature: "53%",
          totalSales: "13470",
          operationalAlert: "16170",
          inventoryLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 68,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          inventoryBreakdownDataVending: [
            {
              title: "FOOD & SNACKS",
              value: 72,
            },
            {
              title: "DRINKS",
              value: 28,
            },
          ],
          avgTemparatureData: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 74.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          salesData: [
            {
              metricName: "foodSnacks",
              percentage: "72%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 63,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "drinks",
              percentage: "28%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 68,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
              ],
            },
          ],
          operationalAlertsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          incidentsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
        },
      },
    },
    {
      id: "vendingMachine#03",
      title: "Vending Machine #03",
      data: {
        day: {
          totalIncidents: "878",
          inventoryLevel: "45%",
          avgTemparature: "35%",
          totalSales: "470",
          operationalAlert: "270",
          inventoryLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 68,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          inventoryBreakdownDataVending: [
            {
              title: "FOOD & SNACKS",
              value: 55,
            },
            {
              title: "DRINKS",
              value: 45,
            },
          ],
          avgTemparatureData: [
            {
              node: "2022-10-11T11:00:00",
              count: 68,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 71.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          salesData: [
            {
              metricName: "foodSnacks",
              percentage: "55%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 60,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
              ],
            },
            {
              metricName: "drinks",
              percentage: "45%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
          operationalAlertsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 63,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          incidentsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 60,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
        },
        weekly: {
          totalIncidents: "8278",
          inventoryLevel: "43%",
          avgTemparature: "50%",
          totalSales: "1070",
          operationalAlert: "2070",
          inventoryLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 64,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 68,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          inventoryBreakdownDataVending: [
            {
              title: "FOOD & SNACKS",
              value: 59,
            },
            {
              title: "DRINKS",
              value: 41,
            },
          ],
          avgTemparatureData: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          salesData: [
            {
              metricName: "foodSnacks",
              percentage: "59%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "drinks",
              percentage: "41%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 68,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
          operationalAlertsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 68,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 65,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          incidentsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
        },
        monthly: {
          totalIncidents: "13178",
          inventoryLevel: "49%",
          avgTemparature: "51%",
          totalSales: "4170",
          operationalAlert: "6170",
          inventoryLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 63,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          inventoryBreakdownDataVending: [
            {
              title: "FOOD & SNACKS",
              value: 71,
            },
            {
              title: "DRINKS",
              value: 29,
            },
          ],
          avgTemparatureData: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 68,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          salesData: [
            {
              metricName: "foodSnacks",
              percentage: "71%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 60,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "drinks",
              percentage: "28%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 60,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
          operationalAlertsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          incidentsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 68,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
        },
        yearly: {
          totalIncidents: "81278",
          inventoryLevel: "64%",
          avgTemparature: "36%",
          totalSales: "16270",
          operationalAlert: "14370",
          inventoryLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          inventoryBreakdownDataVending: [
            {
              title: "FOOD & SNACKS",
              value: 64,
            },
            {
              title: "DRINKS",
              value: 36,
            },
          ],
          avgTemparatureData: [
            {
              node: "2022-10-11T11:00:00",
              count: 69,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          salesData: [
            {
              metricName: "foodSnacks",
              percentage: "64%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
              ],
            },
            {
              metricName: "drinks",
              percentage: "36%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 68,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
          operationalAlertsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 63,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          incidentsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
        },
      },
    },
    {
      id: "vendingMachine#04",
      title: "Vending Machine #04",
      data: {
        day: {
          totalIncidents: "978",
          inventoryLevel: "43%",
          avgTemparature: "57%",
          totalSales: "570",
          operationalAlert: "400",
          inventoryLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          inventoryBreakdownDataVending: [
            {
              title: "FOOD & SNACKS",
              value: 35,
            },
            {
              title: "DRINKS",
              value: 65,
            },
          ],
          avgTemparatureData: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 64,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          salesData: [
            {
              metricName: "foodSnacks",
              percentage: "35%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 68,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "drinks",
              percentage: "65%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
              ],
            },
          ],
          operationalAlertsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          incidentsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 63,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
        },
        weekly: {
          totalIncidents: "6278",
          inventoryLevel: "50%",
          avgTemparature: "50%",
          totalSales: "1270",
          operationalAlert: "2370",
          inventoryLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          inventoryBreakdownDataVending: [
            {
              title: "FOOD & SNACKS",
              value: 55,
            },
            {
              title: "DRINKS",
              value: 45,
            },
          ],
          avgTemparatureData: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          salesData: [
            {
              metricName: "foodSnacks",
              percentage: "55%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
              ],
            },
            {
              metricName: "drinks",
              percentage: "45%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 68,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
          operationalAlertsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 68,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          incidentsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
        },
        monthly: {
          totalIncidents: "21278",
          inventoryLevel: "40%",
          avgTemparature: "60%",
          totalSales: "4070",
          operationalAlert: "5070",
          inventoryLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          inventoryBreakdownDataVending: [
            {
              title: "FOOD & SNACKS",
              value: 30,
            },
            {
              title: "DRINKS",
              value: 70,
            },
          ],
          avgTemparatureData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          salesData: [
            {
              metricName: "foodSnacks",
              percentage: "30%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
              ],
            },
            {
              metricName: "drinks",
              percentage: "70%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 63,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
              ],
            },
          ],
          operationalAlertsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          incidentsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 60,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 68,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
        },
        yearly: {
          totalIncidents: "78278",
          inventoryLevel: "56%",
          avgTemparature: "44%",
          totalSales: "7270",
          operationalAlert: "8670",
          inventoryLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 60,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          inventoryBreakdownDataVending: [
            {
              title: "FOOD & SNACKS",
              value: 60,
            },
            {
              title: "DRINKS",
              value: 40,
            },
          ],
          avgTemparatureData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          salesData: [
            {
              metricName: "foodSnacks",
              percentage: "60%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "drinks",
              percentage: "40%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
          operationalAlertsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          incidentsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 63,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
        },
      },
    },
    {
      id: "vendingMachine#05",
      title: "Vending Machine #05",
      data: {
        day: {
          totalIncidents: "978",
          inventoryLevel: "33%",
          avgTemparature: "43%",
          totalSales: "320",
          operationalAlert: "570",
          inventoryLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          inventoryBreakdownDataVending: [
            {
              title: "FOOD & SNACKS",
              value: 69,
            },
            {
              title: "DRINKS",
              value: 31,
            },
          ],
          avgTemparatureData: [
            {
              node: "2022-10-11T11:00:00",
              count: 68,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          salesData: [
            {
              metricName: "foodSnacks",
              percentage: "69%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 67,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "drinks",
              percentage: "31%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 69,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
              ],
            },
          ],
          operationalAlertsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          incidentsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
        },
        weekly: {
          totalIncidents: "6278",
          inventoryLevel: "40%",
          avgTemparature: "40%",
          totalSales: "1670",
          operationalAlert: "2170",
          inventoryLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 64,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 96,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          inventoryBreakdownDataVending: [
            {
              title: "FOOD & SNACKS",
              value: 63,
            },
            {
              title: "DRINKS",
              value: 37,
            },
          ],
          avgTemparatureData: [
            {
              node: "2022-10-11T11:00:00",
              count: 65,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          salesData: [
            {
              metricName: "foodSnacks",
              percentage: "63%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
              ],
            },
            {
              metricName: "drinks",
              percentage: "37%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
          operationalAlertsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          incidentsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
        },
        monthly: {
          totalIncidents: "15278",
          inventoryLevel: "50%",
          avgTemparature: "50%",
          totalSales: "2370",
          operationalAlert: "3470",
          inventoryLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          inventoryBreakdownDataVending: [
            {
              title: "FOOD & SNACKS",
              value: 57,
            },
            {
              title: "DRINKS",
              value: 43,
            },
          ],
          avgTemparatureData: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 73.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          salesData: [
            {
              metricName: "foodSnacks",
              percentage: "57%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "drinks",
              percentage: "47%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
              ],
            },
          ],
          operationalAlertsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          incidentsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 68,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
        },
        yearly: {
          totalIncidents: "76278",
          inventoryLevel: "50%",
          avgTemparature: "60%",
          totalSales: "11270",
          operationalAlert: "22170",
          inventoryLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          inventoryBreakdownDataVending: [
            {
              title: "FOOD & SNACKS",
              value: 67,
            },
            {
              title: "DRINKS",
              value: 33,
            },
          ],
          avgTemparatureData: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          salesData: [
            {
              metricName: "foodSnacks",
              percentage: "67%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "drinks",
              percentage: "33%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 16,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
          operationalAlertsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 22.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 64,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 74.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          incidentsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
        },
      },
    },
    {
      id: "vendingMachine#06",
      title: "Vending Machine #06",
      data: {
        day: {
          totalIncidents: "978",
          inventoryLevel: "20%",
          avgTemparature: "30%",
          totalSales: "270",
          operationalAlert: "570",
          inventoryLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          inventoryBreakdownDataVending: [
            {
              title: "FOOD & SNACKS",
              value: 73,
            },
            {
              title: "DRINKS",
              value: 27,
            },
          ],
          avgTemparatureData: [
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          salesData: [
            {
              metricName: "foodSnacks",
              percentage: "73%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
              ],
            },
            {
              metricName: "drinks",
              percentage: "27%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
              ],
            },
          ],
          operationalAlertsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 64,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          incidentsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
        },
        weekly: {
          totalIncidents: "7278",
          inventoryLevel: "30%",
          avgTemparature: "40%",
          totalSales: "2170",
          operationalAlert: "2370",
          inventoryLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          inventoryBreakdownDataVending: [
            {
              title: "FOOD & SNACKS",
              value: 57,
            },
            {
              title: "DRINKS",
              value: 43,
            },
          ],
          avgTemparatureData: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          salesData: [
            {
              metricName: "foodSnacks",
              percentage: "57%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
              ],
            },
            {
              metricName: "drinks",
              percentage: "43%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
          operationalAlertsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          incidentsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
        },
        monthly: {
          totalIncidents: "21278",
          inventoryLevel: "50%",
          avgTemparature: "43%",
          totalSales: "6170",
          operationalAlert: "6570",
          inventoryLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          inventoryBreakdownDataVending: [
            {
              title: "FOOD & SNACKS",
              value: 75,
            },
            {
              title: "DRINKS",
              value: 25,
            },
          ],
          avgTemparatureData: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          salesData: [
            {
              metricName: "foodSnacks",
              percentage: "75%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "drinks",
              percentage: "25%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
          operationalAlertsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          incidentsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 24.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
        },
        yearly: {
          totalIncidents: "77278",
          inventoryLevel: "50%",
          avgTemparature: "60%",
          totalSales: "22170",
          operationalAlert: "43170",
          inventoryLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          inventoryBreakdownDataVending: [
            {
              title: "FOOD & SNACKS",
              value: 45,
            },
            {
              title: "DRINKS",
              value: 55,
            },
          ],
          avgTemparatureData: [
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          salesData: [
            {
              metricName: "foodSnacks",
              percentage: "45%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "drinks",
              percentage: "55%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
          operationalAlertsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          incidentsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
        },
      },
    },
    {
      id: "vendingMachine#07",
      title: "Vending Machine #07",
      data: {
        day: {
          totalIncidents: "778",
          inventoryLevel: "40%",
          avgTemparature: "23%",
          totalSales: "313",
          operationalAlert: "570",
          inventoryLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          inventoryBreakdownDataVending: [
            {
              title: "FOOD & SNACKS",
              value: 65,
            },
            {
              title: "DRINKS",
              value: 35,
            },
          ],
          avgTemparatureData: [
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          salesData: [
            {
              metricName: "foodSnacks",
              percentage: "65%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "drinks",
              percentage: "65%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
          operationalAlertsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 68,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          incidentsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 67,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 68,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
        },
        weekly: {
          totalIncidents: "2378",
          inventoryLevel: "43%",
          avgTemparature: "46%",
          totalSales: "1170",
          operationalAlert: "1170",
          inventoryLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 60,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          inventoryBreakdownDataVending: [
            {
              title: "FOOD & SNACKS",
              value: 63,
            },
            {
              title: "DRINKS",
              value: 37,
            },
          ],
          avgTemparatureData: [
            {
              node: "2022-10-11T11:00:00",
              count: 63,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          salesData: [
            {
              metricName: "foodSnacks",
              percentage: "63%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
              ],
            },
            {
              metricName: "drinks",
              percentage: "37%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
              ],
            },
          ],
          operationalAlertsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          incidentsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 60,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
        },
        monthly: {
          totalIncidents: "6278",
          inventoryLevel: "51%",
          avgTemparature: "54%",
          totalSales: "3170",
          operationalAlert: "3270",
          inventoryLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 71.0,
            },
          ],
          inventoryBreakdownDataVending: [
            {
              title: "FOOD & SNACKS",
              value: 62,
            },
            {
              title: "DRINKS",
              value: 38,
            },
          ],
          avgTemparatureData: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 63,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          salesData: [
            {
              metricName: "foodSnacks",
              percentage: "62%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "drinks",
              percentage: "62%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
          operationalAlertsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          incidentsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 65,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
        },
        yearly: {
          totalIncidents: "61278",
          inventoryLevel: "50%",
          avgTemparature: "60%",
          totalSales: "14370",
          operationalAlert: "20170",
          inventoryLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 63,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 75.0,
            },
          ],
          inventoryBreakdownDataVending: [
            {
              title: "FOOD & SNACKS",
              value: 60,
            },
            {
              title: "DRINKS",
              value: 40,
            },
          ],
          avgTemparatureData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          salesData: [
            {
              metricName: "foodSnacks",
              percentage: "60%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "drinks",
              percentage: "40%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
              ],
            },
          ],
          operationalAlertsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 27.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          incidentsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
        },
      },
    },
    {
      id: "vendingMachine#08",
      title: "Vending Machine #08",
      data: {
        day: {
          totalIncidents: "978",
          inventoryLevel: "33%",
          avgTemparature: "43%",
          totalSales: "320",
          operationalAlert: "570",
          inventoryLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          inventoryBreakdownDataVending: [
            {
              title: "FOOD & SNACKS",
              value: 69,
            },
            {
              title: "DRINKS",
              value: 31,
            },
          ],
          avgTemparatureData: [
            {
              node: "2022-10-11T11:00:00",
              count: 68,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          salesData: [
            {
              metricName: "foodSnacks",
              percentage: "69%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 67,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "drinks",
              percentage: "31%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 69,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
              ],
            },
          ],
          operationalAlertsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          incidentsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
        },
        weekly: {
          totalIncidents: "6278",
          inventoryLevel: "40%",
          avgTemparature: "40%",
          totalSales: "1670",
          operationalAlert: "2170",
          inventoryLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 64,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 96,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          inventoryBreakdownDataVending: [
            {
              title: "FOOD & SNACKS",
              value: 63,
            },
            {
              title: "DRINKS",
              value: 37,
            },
          ],
          avgTemparatureData: [
            {
              node: "2022-10-11T11:00:00",
              count: 65,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          salesData: [
            {
              metricName: "foodSnacks",
              percentage: "63%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
              ],
            },
            {
              metricName: "drinks",
              percentage: "37%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
          operationalAlertsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          incidentsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
        },
        monthly: {
          totalIncidents: "15278",
          inventoryLevel: "50%",
          avgTemparature: "50%",
          totalSales: "2370",
          operationalAlert: "3470",
          inventoryLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          inventoryBreakdownDataVending: [
            {
              title: "FOOD & SNACKS",
              value: 57,
            },
            {
              title: "DRINKS",
              value: 43,
            },
          ],
          avgTemparatureData: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 73.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          salesData: [
            {
              metricName: "foodSnacks",
              percentage: "57%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "drinks",
              percentage: "47%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
              ],
            },
          ],
          operationalAlertsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          incidentsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 68,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
        },
        yearly: {
          totalIncidents: "76278",
          inventoryLevel: "50%",
          avgTemparature: "60%",
          totalSales: "11270",
          operationalAlert: "22170",
          inventoryLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          inventoryBreakdownDataVending: [
            {
              title: "FOOD & SNACKS",
              value: 67,
            },
            {
              title: "DRINKS",
              value: 33,
            },
          ],
          avgTemparatureData: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          salesData: [
            {
              metricName: "foodSnacks",
              percentage: "67%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "drinks",
              percentage: "33%",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 16,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
          operationalAlertsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 22.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 64,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 74.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          incidentsDataVending: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
        },
      },
    },
  ],
};
export default analyticsVendingMachines;
