const palette = {
  //  mode: 'light',
  palette: {
    primary: {
      main: "",
      light: "",
      dark: "",
    },
    secondary: {
      main: "",
      light: "",
      dark: "",
    },
  },
  markerColors: {
    events: "#1E9C09",
    incidents: "#FF5910",
    opAlerts: "#E13827",
    foreground: "#FFFFFF",
  },
  success: {},
  error: {},
  warning: {},
  info: {},
  black: "#000000",
  white: "#ffffff",
  grey1: "#d7d7d7",
  grey2: "#e9e9e9",
  grey3: "#d9d9d9",
  grey4: "#242424",
  grey5: "#999999",
  grey6: "#F8F9FA",
  grey7: "#A4A4A4",
  grey8: "#e8e8e8",
  grey9: "#F5F5F5",
  grey10: "#4E4E4E",
  lightGrey: "#A8A8A8",
  darkGreyOne: "#6B6B6B",
  darkGreyTwo: "#757575",
  darkGreyThree: "#797676",
  red: "#FF4359",
  darkRedOne: "#FF7A7A",
  darkRedTwo: "#FF435A",
  green: "",
  lightGreenOne: "#9CE3E3",
  darkGreenOne: "#70CDCD",
  darkGreenTwo: "#6EB662",
  blue: "#084476",
  lightBlueOne: "#949FFF",
  lightBlueTwo: "#6978FA",
  lightBlueThree: "#00A3FF",
  lightBlueFour: "#5784F1",
  lightBlueFive: "#60D5FA",
  lightBlueSix: "#7BA0FC",
  lightBlueSeven: "#5FB5E5",
  lightBlueEight: "#F4FAFE",
  yellow: "#FACD89",
  orange: "#FABB70",
  orange2: "#FF5910",
  darkOrange: "#F87E3A",
  purple: "#A97FED",
  purpleOne: "#8C45FF",
  pink: "#FE9EAF",
  lightPinkOne: "#FFA8A8",
  tabsColor: "#397FBF",
  analyticsTabButtonColor: "#218AC5",
  analyticsTabButtonBorder: "#153B50",
  speedometerTrackStrokeColor: "#293338",
  darkBlue: "#466475",
  lightGreyOne: "#AAAAAA",
  lightGreyTwo: "#DCDADA",
  logoBackgroundBlue: "rgba(0, 45, 114, 0.77)",
  logoBackgroundBlueOne: "rgba(19, 24, 54, 0.85)",
  logoBackgroundBlack: "rgba(0, 0, 0, 0.85)",
  circularGaugeBackground: "rgba(0, 0, 0, 0.4)",
  circularGaugeForeground: "#252626",

  // sccc
  //Gray
  darkGrayOne: "#2C2C2C",
  darkGrayTwo: "#1f1f1f",
  darkGrayThree: "#191919",
  darkGrayFour: "#2A2A2A",
  darkGrayFive: "#373737",
  darkGraySix: "#1a1a1a",
  darkGraySeven: " #111",
  darkGrayEight: "#4e4f57",
  lightGrayOne: "#767575",
  lightGrayTwo: "#5B5B5B",
  lightGrayThree: "#d7d7d7",
  lightGrayFour: "585858",
  lightGrayFive: "#A8A8A8",

  //Orange
  logoOrange: "#FF5910",
  lightOrange: "#FFB763",
  darkOrange: "#FA7A43",

  //yellow
  scccYellowOne: "#FFF844",
  scccYellowTwo: "#fff744",
  scccYellowThree: "#ECE115",
  scccYellowFour: "#D9D355",

  //Green
  scccGreenOne: "#21E8D0",
  scccLightGreenOne: "#79C872",
  scccLightGreenTwo: "#6EDA5D",

  //Red
  scccLightRedOne: "#CB5050",

  //Blue
  scccBlueOne: "#002D72",
};

export default palette;
