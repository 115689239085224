const analyticsSecurity = {
  analyticsList: [
    {
      id: "overAll",
      title: "OVERALL ANALYTICS",
      data: {
        day: {
          totalIncidents: "780",
          tailGating: "600",
          crowdForming: "500",
          unauthorizedAccess: "640",
          operationalAlert: "670",
          crowdFormingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 96,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          tailGatingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
          ],
          unauthorizedAccessData: [
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          violationsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 15.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsBreakdownSecurity: [
            {
              title: "CROWD FORMING",
              value: 20,
            },
            {
              title: "UNAUTHORIZE ACCESS",
              value: 30,
            },
            {
              title: "VIOLATIONS",
              value: 40,
            },
            {
              title: "TAILGATING",
              value: 10,
            },
          ],
        },
        weekly: {
          totalIncidents: "2780",
          tailGating: "1672",
          crowdForming: "1770",
          unauthorizedAccess: "2340",
          operationalAlert: "3470",
          crowdFormingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 82.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 70.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 80.0,
            },
          ],
          tailGatingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 82.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 70.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 80.0,
            },
          ],
          unauthorizedAccessData: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 82.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 70.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 80.0,
            },
          ],
          violationsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 82.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 70.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 80.0,
            },
          ],
          incidentsBreakdownSecurity: [
            {
              title: "CROWD FORMING",
              value: 12,
            },
            {
              title: "UNAUTHORIZE ACCESS",
              value: 22,
            },
            {
              title: "VIOLATIONS",
              value: 32,
            },
            {
              title: "TAILGATING",
              value: 52,
            },
          ],
        },
        monthly: {
          totalIncidents: "12780",
          tailGating: "1122",
          crowdForming: "1220",
          unauthorizedAccess: "1340",
          operationalAlert: "1370",
          crowdFormingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
          ],
          tailGatingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 15.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
          ],
          unauthorizedAccessData: [
            {
              node: "2022-10-11T11:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 35.0,
            },
          ],
          violationsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
          ],
          incidentsBreakdownSecurity: [
            {
              title: "CROWD FORMING",
              value: 40,
            },
            {
              title: "UNAUTHORIZE ACCESS",
              value: 30,
            },
            {
              title: "VIOLATIONS",
              value: 10,
            },
            {
              title: "TAILGATING",
              value: 10,
            },
          ],
        },
        yearly: {
          totalIncidents: "22780",
          tailGating: "2212",
          crowdForming: "1220",
          unauthorizedAccess: "4340",
          operationalAlert: "5670",
          crowdFormingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          tailGatingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          unauthorizedAccessData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
          ],
          violationsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 35.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsBreakdownSecurity: [
            {
              title: "CROWD FORMING",
              value: 30,
            },
            {
              title: "UNAUTHORIZE ACCESS",
              value: 30,
            },
            {
              title: "VIOLATIONS",
              value: 25,
            },
            {
              title: "TAILGATING",
              value: 15,
            },
          ],
        },
      },
    },
    {
      id: "pregame",
      title: "Pregame",
      data: {
        day: {
          totalIncidents: "1880",
          tailGating: "562",
          crowdForming: "480",
          unauthorizedAccess: "340",
          operationalAlert: "470",
          crowdFormingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          tailGatingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
          ],
          unauthorizedAccessData: [
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
          ],
          violationsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 35.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
          ],
          incidentsBreakdownSecurity: [
            {
              title: "CROWD FORMING",
              value: 50,
            },
            {
              title: "UNAUTHORIZE ACCESS",
              value: 15,
            },
            {
              title: "VIOLATIONS",
              value: 20,
            },
            {
              title: "TAILGATING",
              value: 15,
            },
          ],
        },
        weekly: {
          totalIncidents: "12780",
          tailGating: "1232",
          crowdForming: "2310",
          unauthorizedAccess: "2340",
          operationalAlert: "2370",
          crowdFormingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
          ],
          tailGatingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          unauthorizedAccessData: [
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          violationsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsBreakdownSecurity: [
            {
              title: "CROWD FORMING",
              value: 40,
            },
            {
              title: "UNAUTHORIZE ACCESS",
              value: 15,
            },
            {
              title: "VIOLATIONS",
              value: 15,
            },
            {
              title: "TAILGATING",
              value: 30,
            },
          ],
        },
        monthly: {
          totalIncidents: "22780",
          tailGating: "2222",
          crowdForming: "2210",
          unauthorizedAccess: "2340",
          operationalAlert: "2470",
          crowdFormingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 35.0,
            },
          ],
          tailGatingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          unauthorizedAccessData: [
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
          ],
          violationsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 95.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
          ],
          incidentsBreakdownSecurity: [
            {
              title: "CROWD FORMING",
              value: 20,
            },
            {
              title: "UNAUTHORIZE ACCESS",
              value: 20,
            },
            {
              title: "VIOLATIONS",
              value: 20,
            },
            {
              title: "TAILGATING",
              value: 40,
            },
          ],
        },
        yearly: {
          totalIncidents: "232780",
          tailGating: "2312",
          crowdForming: "2310",
          unauthorizedAccess: "2540",
          operationalAlert: "2670",
          crowdFormingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
          ],
          tailGatingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
          ],
          unauthorizedAccessData: [
            {
              node: "2022-10-11T11:00:00",
              count: 60.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
          ],
          violationsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
          ],
          incidentsBreakdownSecurity: [
            {
              title: "CROWD FORMING",
              value: 30,
            },
            {
              title: "UNAUTHORIZE ACCESS",
              value: 30,
            },
            {
              title: "VIOLATIONS",
              value: 30,
            },
            {
              title: "TAILGATING",
              value: 10,
            },
          ],
        },
      },
    },
    {
      id: "inGame",
      title: "In Game",
      data: {
        day: {
          totalIncidents: "1780",
          tailGating: "122",
          crowdForming: "210",
          unauthorizedAccess: "640",
          operationalAlert: "770",
          crowdFormingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          tailGatingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 35.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          unauthorizedAccessData: [
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
          ],
          violationsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 35.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
          ],
          incidentsBreakdownSecurity: [
            {
              title: "CROWD FORMING",
              value: 40,
            },
            {
              title: "UNAUTHORIZE ACCESS",
              value: 15,
            },
            {
              title: "VIOLATIONS",
              value: 15,
            },
            {
              title: "TAILGATING",
              value: 30,
            },
          ],
        },
        weekly: {
          totalIncidents: "12980",
          tailGating: "2312",
          crowdForming: "2110",
          unauthorizedAccess: "2440",
          operationalAlert: "2570",
          crowdFormingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          tailGatingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 35.0,
            },
          ],
          unauthorizedAccessData: [
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 35.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
          ],
          violationsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsBreakdownSecurity: [
            {
              title: "CROWD FORMING",
              value: 25,
            },
            {
              title: "UNAUTHORIZE ACCESS",
              value: 25,
            },
            {
              title: "VIOLATIONS",
              value: 25,
            },
            {
              title: "TAILGATING",
              value: 25,
            },
          ],
        },
        monthly: {
          totalIncidents: "22780",
          tailGating: "2212",
          crowdForming: "2210",
          unauthorizedAccess: "2240",
          operationalAlert: "2270",
          crowdFormingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          tailGatingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
          ],
          unauthorizedAccessData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
          ],
          violationsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 95.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
          ],
          incidentsBreakdownSecurity: [
            {
              title: "CROWD FORMING",
              value: 30,
            },
            {
              title: "UNAUTHORIZE ACCESS",
              value: 40,
            },
            {
              title: "VIOLATIONS",
              value: 15,
            },
            {
              title: "TAILGATING",
              value: 15,
            },
          ],
        },
        yearly: {
          totalIncidents: "32780",
          tailGating: "2312",
          crowdForming: "2410",
          unauthorizedAccess: "2540",
          operationalAlert: "2670",
          crowdFormingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          tailGatingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
          ],
          unauthorizedAccessData: [
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
          ],
          violationsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
          ],
          incidentsBreakdownSecurity: [
            {
              title: "CROWD FORMING",
              value: 35,
            },
            {
              title: "UNAUTHORIZE ACCESS",
              value: 25,
            },
            {
              title: "VIOLATIONS",
              value: 25,
            },
            {
              title: "TAILGATING",
              value: 15,
            },
          ],
        },
      },
    },
    {
      id: "postGame",
      title: "Post Game",
      data: {
        day: {
          totalIncidents: "1580",
          tailGating: "212",
          crowdForming: "230",
          unauthorizedAccess: "340",
          operationalAlert: "470",
          crowdFormingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 35.0,
            },
          ],
          tailGatingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 95.0,
            },
          ],
          unauthorizedAccessData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          violationsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
          ],
          incidentsBreakdownSecurity: [
            {
              title: "CROWD FORMING",
              value: 20,
            },
            {
              title: "UNAUTHORIZE ACCESS",
              value: 20,
            },
            {
              title: "VIOLATIONS",
              value: 25,
            },
            {
              title: "TAILGATING",
              value: 35,
            },
          ],
        },
        weekly: {
          totalIncidents: "22780",
          tailGating: "2212",
          crowdForming: "2210",
          unauthorizedAccess: "2240",
          operationalAlert: "2270",
          crowdFormingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          tailGatingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          unauthorizedAccessData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
          ],
          violationsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
          ],
          incidentsBreakdownSecurity: [
            {
              title: "CROWD FORMING",
              value: 35,
            },
            {
              title: "UNAUTHORIZE ACCESS",
              value: 25,
            },
            {
              title: "VIOLATIONS",
              value: 25,
            },
            {
              title: "TAILGATING",
              value: 15,
            },
          ],
        },
        monthly: {
          totalIncidents: "12580",
          tailGating: "2212",
          crowdForming: "2310",
          unauthorizedAccess: "2340",
          operationalAlert: "2170",
          crowdFormingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
          ],
          tailGatingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
          ],
          unauthorizedAccessData: [
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
          ],
          violationsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsBreakdownSecurity: [
            {
              title: "CROWD FORMING",
              value: 25,
            },
            {
              title: "UNAUTHORIZE ACCESS",
              value: 25,
            },
            {
              title: "VIOLATIONS",
              value: 35,
            },
            {
              title: "TAILGATING",
              value: 15,
            },
          ],
        },
        yearly: {
          totalIncidents: "22780",
          tailGating: "2212",
          crowdForming: "2310",
          unauthorizedAccess: "2540",
          operationalAlert: "2770",
          crowdFormingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
          ],
          tailGatingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 95.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
          ],
          unauthorizedAccessData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          violationsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 25.0,
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
          ],
          incidentsBreakdownSecurity: [
            {
              title: "CROWD FORMING",
              value: 40,
            },
            {
              title: "UNAUTHORIZE ACCESS",
              value: 20,
            },
            {
              title: "VIOLATIONS",
              value: 20,
            },
            {
              title: "TAILGATING",
              value: 20,
            },
          ],
        },
      },
    },
    {
      id: "events",
      title: "Events",
      data: {
        day: {
          totalIncidents: "1380",
          tailGating: "312",
          crowdForming: "310",
          unauthorizedAccess: "340",
          operationalAlert: "370",
          crowdFormingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          tailGatingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          unauthorizedAccessData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          violationsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 95.0,
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 95.0,
            },
          ],
          incidentsBreakdownSecurity: [
            {
              title: "CROWD FORMING",
              value: 25,
            },
            {
              title: "UNAUTHORIZE ACCESS",
              value: 35,
            },
            {
              title: "VIOLATIONS",
              value: 25,
            },
            {
              title: "TAILGATING",
              value: 15,
            },
          ],
        },
        weekly: {
          totalIncidents: "12280",
          tailGating: "2212",
          crowdForming: "2310",
          unauthorizedAccess: "2340",
          operationalAlert: "2570",
          crowdFormingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
          ],
          tailGatingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          unauthorizedAccessData: [
            {
              node: "2022-10-11T11:00:00",
              count: 76.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          violationsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsBreakdownSecurity: [
            {
              title: "CROWD FORMING",
              value: 30,
            },
            {
              title: "UNAUTHORIZE ACCESS",
              value: 30,
            },
            {
              title: "VIOLATIONS",
              value: 15,
            },
            {
              title: "TAILGATING",
              value: 25,
            },
          ],
        },
        monthly: {
          totalIncidents: "13780",
          tailGating: "2112",
          crowdForming: "2310",
          unauthorizedAccess: "2540",
          operationalAlert: "2670",
          crowdFormingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          tailGatingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
          ],
          unauthorizedAccessData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          violationsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
          ],
          incidentsBreakdownSecurity: [
            {
              title: "CROWD FORMING",
              value: 10,
            },
            {
              title: "UNAUTHORIZE ACCESS",
              value: 10,
            },
            {
              title: "VIOLATIONS",
              value: 10,
            },
            {
              title: "TAILGATING",
              value: 70,
            },
          ],
        },
        yearly: {
          totalIncidents: "32880",
          tailGating: "3212",
          crowdForming: "3110",
          unauthorizedAccess: "2340",
          operationalAlert: "4170",
          crowdFormingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
          ],
          tailGatingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
          ],
          unauthorizedAccessData: [
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 35.0,
            },
          ],
          violationsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
          ],
          incidentsBreakdownSecurity: [
            {
              title: "CROWD FORMING",
              value: 15,
            },
            {
              title: "UNAUTHORIZE ACCESS",
              value: 20,
            },
            {
              title: "VIOLATIONS",
              value: 35,
            },
            {
              title: "TAILGATING",
              value: 30,
            },
          ],
        },
      },
    },
    {
      id: "outdoors",
      title: "Outdoors",
      data: {
        day: {
          totalIncidents: "1780",
          tailGating: "412",
          crowdForming: "510",
          unauthorizedAccess: "640",
          operationalAlert: "270",
          crowdFormingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          tailGatingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 35.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          unauthorizedAccessData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
          ],
          violationsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 95.0,
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 73.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
          ],
          incidentsBreakdownSecurity: [
            {
              title: "CROWD FORMING",
              value: 15,
            },
            {
              title: "UNAUTHORIZE ACCESS",
              value: 15,
            },
            {
              title: "VIOLATIONS",
              value: 15,
            },
            {
              title: "TAILGATING",
              value: 55,
            },
          ],
        },
        weekly: {
          totalIncidents: "12380",
          tailGating: "2112",
          crowdForming: "2310",
          unauthorizedAccess: "2440",
          operationalAlert: "2570",
          crowdFormingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 35.0,
            },
          ],
          tailGatingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          unauthorizedAccessData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          violationsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsBreakdownSecurity: [
            {
              title: "CROWD FORMING",
              value: 20,
            },
            {
              title: "UNAUTHORIZE ACCESS",
              value: 30,
            },
            {
              title: "VIOLATIONS",
              value: 20,
            },
            {
              title: "TAILGATING",
              value: 30,
            },
          ],
        },
        monthly: {
          totalIncidents: "21780",
          tailGating: "2212",
          crowdForming: "2310",
          unauthorizedAccess: "2440",
          operationalAlert: "2570",
          crowdFormingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 35.0,
            },
          ],
          tailGatingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 95.0,
            },
          ],
          unauthorizedAccessData: [
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          violationsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsBreakdownSecurity: [
            {
              title: "CROWD FORMING",
              value: 30,
            },
            {
              title: "UNAUTHORIZE ACCESS",
              value: 30,
            },
            {
              title: "VIOLATIONS",
              value: 25,
            },
            {
              title: "TAILGATING",
              value: 15,
            },
          ],
        },
        yearly: {
          totalIncidents: "32780",
          tailGating: "2312",
          crowdForming: "2510",
          unauthorizedAccess: "2640",
          operationalAlert: "2870",
          crowdFormingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 25.0,
            },
          ],
          tailGatingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 95.0,
            },
          ],
          unauthorizedAccessData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          violationsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsBreakdownSecurity: [
            {
              title: "CROWD FORMING",
              value: 30,
            },
            {
              title: "UNAUTHORIZE ACCESS",
              value: 25,
            },
            {
              title: "VIOLATIONS",
              value: 15,
            },
            {
              title: "TAILGATING",
              value: 30,
            },
          ],
        },
      },
    },
    {
      id: "concessions",
      title: "Concessions",
      data: {
        day: {
          totalIncidents: "1680",
          tailGating: "312",
          crowdForming: "310",
          unauthorizedAccess: "640",
          operationalAlert: "770",
          crowdFormingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 35.0,
            },
          ],
          tailGatingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          unauthorizedAccessData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
          ],
          violationsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
          ],
          incidentsBreakdownSecurity: [
            {
              title: "CROWD FORMING",
              value: 33,
            },
            {
              title: "UNAUTHORIZE ACCESS",
              value: 33,
            },
            {
              title: "VIOLATIONS",
              value: 20,
            },
            {
              title: "TAILGATING",
              value: 14,
            },
          ],
        },
        weekly: {
          totalIncidents: "11780",
          tailGating: "2112",
          crowdForming: "2210",
          unauthorizedAccess: "2440",
          operationalAlert: "2670",
          crowdFormingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
          ],
          tailGatingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          unauthorizedAccessData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 95.0,
            },
          ],
          violationsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsBreakdownSecurity: [
            {
              title: "CROWD FORMING",
              value: 25,
            },
            {
              title: "UNAUTHORIZE ACCESS",
              value: 24,
            },
            {
              title: "VIOLATIONS",
              value: 26,
            },
            {
              title: "TAILGATING",
              value: 25,
            },
          ],
        },
        monthly: {
          totalIncidents: "11680",
          tailGating: "2112",
          crowdForming: "2210",
          unauthorizedAccess: "2340",
          operationalAlert: "2370",
          crowdFormingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          tailGatingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          unauthorizedAccessData: [
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
          ],
          violationsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 95.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsBreakdownSecurity: [
            {
              title: "CROWD FORMING",
              value: 20,
            },
            {
              title: "UNAUTHORIZE ACCESS",
              value: 25,
            },
            {
              title: "VIOLATIONS",
              value: 25,
            },
            {
              title: "TAILGATING",
              value: 30,
            },
          ],
        },
        yearly: {
          totalIncidents: "32780",
          tailGating: "2312",
          crowdForming: "3110",
          unauthorizedAccess: "2440",
          operationalAlert: "2570",
          crowdFormingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          tailGatingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          unauthorizedAccessData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          violationsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsBreakdownSecurity: [
            {
              title: "CROWD FORMING",
              value: 25,
            },
            {
              title: "UNAUTHORIZE ACCESS",
              value: 25,
            },
            {
              title: "VIOLATIONS",
              value: 30,
            },
            {
              title: "TAILGATING",
              value: 20,
            },
          ],
        },
      },
    },
    {
      id: "tailgateAreas",
      title: "Tailgate Areas",
      data: {
        day: {
          totalIncidents: "1580",
          tailGating: "312",
          crowdForming: "410",
          unauthorizedAccess: "540",
          operationalAlert: "770",
          crowdFormingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          tailGatingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          unauthorizedAccessData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          violationsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
          ],
          incidentsBreakdownSecurity: [
            {
              title: "CROWD FORMING",
              value: 25,
            },
            {
              title: "UNAUTHORIZE ACCESS",
              value: 30,
            },
            {
              title: "VIOLATIONS",
              value: 30,
            },
            {
              title: "TAILGATING",
              value: 25,
            },
          ],
        },
        weekly: {
          totalIncidents: "10180",
          tailGating: "2112",
          crowdForming: "2110",
          unauthorizedAccess: "2140",
          operationalAlert: "2170",
          crowdFormingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          tailGatingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 78.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          unauthorizedAccessData: [
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
          ],
          violationsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsBreakdownSecurity: [
            {
              title: "CROWD FORMING",
              value: 40,
            },
            {
              title: "UNAUTHORIZE ACCESS",
              value: 20,
            },
            {
              title: "VIOLATIONS",
              value: 15,
            },
            {
              title: "TAILGATING",
              value: 15,
            },
          ],
        },
        monthly: {
          totalIncidents: "22780",
          tailGating: "2312",
          crowdForming: "2310",
          unauthorizedAccess: "2340",
          operationalAlert: "2370",
          crowdFormingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          tailGatingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
          ],
          unauthorizedAccessData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
          ],
          violationsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsBreakdownSecurity: [
            {
              title: "CROWD FORMING",
              value: 30,
            },
            {
              title: "UNAUTHORIZE ACCESS",
              value: 40,
            },
            {
              title: "VIOLATIONS",
              value: 20,
            },
            {
              title: "TAILGATING",
              value: 10,
            },
          ],
        },
        yearly: {
          totalIncidents: "32780",
          tailGating: "2312",
          crowdForming: "2410",
          unauthorizedAccess: "2540",
          operationalAlert: "2670",
          crowdFormingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          tailGatingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          unauthorizedAccessData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          violationsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsBreakdownSecurity: [
            {
              title: "CROWD FORMING",
              value: 30,
            },
            {
              title: "UNAUTHORIZE ACCESS",
              value: 30,
            },
            {
              title: "VIOLATIONS",
              value: 40,
            },
            {
              title: "TAILGATING",
              value: 30,
            },
          ],
        },
      },
    },
    {
      id: "vipAreas",
      title: "VIP Areas",
      data: {
        day: {
          totalIncidents: "2780",
          tailGating: "312",
          crowdForming: "610",
          unauthorizedAccess: "740",
          operationalAlert: "870",
          crowdFormingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
          ],
          tailGatingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          unauthorizedAccessData: [
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
          ],
          violationsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsBreakdownSecurity: [
            {
              title: "CROWD FORMING",
              value: 25,
            },
            {
              title: "UNAUTHORIZE ACCESS",
              value: 25,
            },
            {
              title: "VIOLATIONS",
              value: 25,
            },
            {
              title: "TAILGATING",
              value: 25,
            },
          ],
        },
        weekly: {
          totalIncidents: "10780",
          tailGating: "2112",
          crowdForming: "2210",
          unauthorizedAccess: "2540",
          operationalAlert: "2570",
          crowdFormingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
          ],
          tailGatingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
          ],
          unauthorizedAccessData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
          ],
          violationsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsBreakdownSecurity: [
            {
              title: "CROWD FORMING",
              value: 30,
            },
            {
              title: "UNAUTHORIZE ACCESS",
              value: 20,
            },
            {
              title: "VIOLATIONS",
              value: 20,
            },
            {
              title: "TAILGATING",
              value: 30,
            },
          ],
        },
        monthly: {
          totalIncidents: "22780",
          tailGating: "2312",
          crowdForming: "2410",
          unauthorizedAccess: "2540",
          operationalAlert: "2870",
          crowdFormingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          tailGatingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
          ],
          unauthorizedAccessData: [
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 95.0,
            },
          ],
          violationsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsBreakdownSecurity: [
            {
              title: "CROWD FORMING",
              value: 50,
            },
            {
              title: "UNAUTHORIZE ACCESS",
              value: 10,
            },
            {
              title: "VIOLATIONS",
              value: 20,
            },
            {
              title: "TAILGATING",
              value: 20,
            },
          ],
        },
        yearly: {
          totalIncidents: "33780",
          tailGating: "2312",
          crowdForming: "2510",
          unauthorizedAccess: "2640",
          operationalAlert: "2870",
          crowdFormingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 95.0,
            },
          ],
          tailGatingData: [
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
          ],
          unauthorizedAccessData: [
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          violationsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsBreakdownSecurity: [
            {
              title: "CROWD FORMING",
              value: 30,
            },
            {
              title: "UNAUTHORIZE ACCESS",
              value: 30,
            },
            {
              title: "VIOLATIONS",
              value: 20,
            },
            {
              title: "TAILGATING",
              value: 20,
            },
          ],
        },
      },
    },
  ],
};
export default analyticsSecurity;
