const analyticsEntranceExit = {
  analyticsList: [
    {
      id: "overAll",
      title: "OVERALL ANALYTICS",
      data: {
        day: {
          totalIncidents: "378",
          occupancy: "30%",
          totalVisitors: "200",
          avgWaitTime: "12",
          operationalAlert: "170",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 71.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 60,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 65,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 71.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 63,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 60,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
              ],
            },
          ],
        },
        weekly: {
          totalIncidents: "378",
          occupancy: "745%",
          totalVisitors: "200",
          avgWaitTime: "45",
          operationalAlert: "390",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
        },
        monthly: {
          totalIncidents: "8278",
          occupancy: "45%",
          totalVisitors: "1500",
          avgWaitTime: "55",
          operationalAlert: "3870",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 63,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 63,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
              ],
            },
          ],
        },
        yearly: {
          totalIncidents: "61278",
          occupancy: "60%",
          totalVisitors: "5500",
          avgWaitTime: "15170",
          operationalAlert: "21170",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 65,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 68,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 71.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 63,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 63,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 65,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 71.0,
                },
              ],
            },
          ],
        },
      },
    },
    {
      id: "entranceExit01",
      title: "Entrance & Exit #01",
      data: {
        day: {
          totalIncidents: "678",
          occupancy: "43%",
          totalVisitors: "300",
          avgWaitTime: "24",
          operationalAlert: "270",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 60,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 27.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
        },
        weekly: {
          totalIncidents: "3178",
          occupancy: "45%",
          totalVisitors: "255",
          avgWaitTime: "53",
          operationalAlert: "370",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 60,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 71.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 69,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
        },
        monthly: {
          totalIncidents: "7278",
          occupancy: "55%",
          totalVisitors: "2500",
          avgWaitTime: "52",
          operationalAlert: "1270",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 65,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 68,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
              ],
            },
          ],
        },
        yearly: {
          totalIncidents: "72278",
          occupancy: "65%",
          totalVisitors: "13500",
          avgWaitTime: "55",
          operationalAlert: "21170",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
              ],
            },
          ],
        },
      },
    },
    {
      id: "exit02",
      title: "Exit #02",
      data: {
        day: {
          totalIncidents: "378",
          occupancy: "30%",
          totalVisitors: "200",
          avgWaitTime: "12",
          operationalAlert: "170",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 71.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 60,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 65,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 71.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 63,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 60,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
              ],
            },
          ],
        },
        weekly: {
          totalIncidents: "378",
          occupancy: "745%",
          totalVisitors: "200",
          avgWaitTime: "45",
          operationalAlert: "390",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
        },
        monthly: {
          totalIncidents: "8278",
          occupancy: "45%",
          totalVisitors: "1500",
          avgWaitTime: "55",
          operationalAlert: "3870",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 63,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 63,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
              ],
            },
          ],
        },
        yearly: {
          totalIncidents: "61278",
          occupancy: "60%",
          totalVisitors: "5500",
          avgWaitTime: "15170",
          operationalAlert: "21170",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 65,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 68,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 71.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 63,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 63,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 65,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 71.0,
                },
              ],
            },
          ],
        },
      },
    },
    {
      id: "entranceExit03",
      title: "Entrance & Exit #03",
      data: {
        day: {
          totalIncidents: "678",
          occupancy: "43%",
          totalVisitors: "300",
          avgWaitTime: "24",
          operationalAlert: "270",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 60,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 27.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
        },
        weekly: {
          totalIncidents: "3178",
          occupancy: "45%",
          totalVisitors: "255",
          avgWaitTime: "53",
          operationalAlert: "370",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 60,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 71.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 69,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
        },
        monthly: {
          totalIncidents: "7278",
          occupancy: "55%",
          totalVisitors: "2500",
          avgWaitTime: "52",
          operationalAlert: "1270",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 65,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 68,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
              ],
            },
          ],
        },
        yearly: {
          totalIncidents: "72278",
          occupancy: "65%",
          totalVisitors: "13500",
          avgWaitTime: "55",
          operationalAlert: "21170",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
              ],
            },
          ],
        },
      },
    },
    {
      id: "entranceExit04",
      title: "Entrance & Exit #04",
      data: {
        day: {
          totalIncidents: "878",
          occupancy: "25%",
          totalVisitors: "550",
          avgWaitTime: "14",
          operationalAlert: "470",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 16,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
              ],
            },
          ],
        },
        weekly: {
          totalIncidents: "2278",
          occupancy: "43%",
          totalVisitors: "1500",
          avgWaitTime: "23",
          operationalAlert: "1170",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 64,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 60,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 68,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
              ],
            },
          ],
        },
        monthly: {
          totalIncidents: "3278",
          occupancy: "31%",
          totalVisitors: "1100",
          avgWaitTime: "41",
          operationalAlert: "2170",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 63,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
        },
        yearly: {
          totalIncidents: "71278",
          occupancy: "45%",
          totalVisitors: "12500",
          avgWaitTime: "51",
          operationalAlert: "21170",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 63,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 63,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
        },
      },
    },
    {
      id: "entranceExit05",
      title: "Entrance & Exit #05",
      data: {
        day: {
          totalIncidents: "878",
          occupancy: "25%",
          totalVisitors: "550",
          avgWaitTime: "14",
          operationalAlert: "470",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 16,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
              ],
            },
          ],
        },
        weekly: {
          totalIncidents: "2278",
          occupancy: "43%",
          totalVisitors: "1500",
          avgWaitTime: "23",
          operationalAlert: "1170",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 64,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 60,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 68,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
              ],
            },
          ],
        },
        monthly: {
          totalIncidents: "3278",
          occupancy: "31%",
          totalVisitors: "1100",
          avgWaitTime: "41",
          operationalAlert: "2170",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 63,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
        },
        yearly: {
          totalIncidents: "71278",
          occupancy: "45%",
          totalVisitors: "12500",
          avgWaitTime: "51",
          operationalAlert: "21170",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 63,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 63,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
        },
      },
    },
    {
      id: "entrance06",
      title: "Entrance #06",
      data: {
        day: {
          totalIncidents: "978",
          occupancy: "31%",
          totalVisitors: "210",
          avgWaitTime: "10",
          operationalAlert: "270",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 68,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
              ],
            },
          ],
        },
        weekly: {
          totalIncidents: "2190",
          occupancy: "45%",
          totalVisitors: "1200",
          avgWaitTime: "47",
          operationalAlert: "1170",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 96,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 64,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
        },
        monthly: {
          totalIncidents: "8278",
          occupancy: "56%",
          totalVisitors: "2310",
          avgWaitTime: "40",
          operationalAlert: "3170",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 96,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 96,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 96,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
        },
        yearly: {
          totalIncidents: "82278",
          occupancy: "59%",
          totalVisitors: "22500",
          avgWaitTime: "56",
          operationalAlert: "25370",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 68,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
        },
      },
    },
    {
      id: "entranceExit07",
      title: "Entrance & Exit #07",
      data: {
        day: {
          totalIncidents: "878",
          occupancy: "25%",
          totalVisitors: "550",
          avgWaitTime: "14",
          operationalAlert: "470",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 16,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
              ],
            },
          ],
        },
        weekly: {
          totalIncidents: "2278",
          occupancy: "43%",
          totalVisitors: "1500",
          avgWaitTime: "23",
          operationalAlert: "1170",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 64,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 60,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 68,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
              ],
            },
          ],
        },
        monthly: {
          totalIncidents: "3278",
          occupancy: "31%",
          totalVisitors: "1100",
          avgWaitTime: "41",
          operationalAlert: "2170",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 63,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
        },
        yearly: {
          totalIncidents: "71278",
          occupancy: "45%",
          totalVisitors: "12500",
          avgWaitTime: "51",
          operationalAlert: "21170",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 63,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 63,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
        },
      },
    },
    {
      id: "entranceExit08",
      title: "Entrance & Exit #08",
      data: {
        day: {
          totalIncidents: "978",
          occupancy: "31%",
          totalVisitors: "210",
          avgWaitTime: "10",
          operationalAlert: "270",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 68,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
              ],
            },
          ],
        },
        weekly: {
          totalIncidents: "2190",
          occupancy: "45%",
          totalVisitors: "1200",
          avgWaitTime: "47",
          operationalAlert: "1170",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 96,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 64,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
        },
        monthly: {
          totalIncidents: "8278",
          occupancy: "56%",
          totalVisitors: "2310",
          avgWaitTime: "40",
          operationalAlert: "3170",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 96,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 96,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 96,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
        },
        yearly: {
          totalIncidents: "82278",
          occupancy: "59%",
          totalVisitors: "22500",
          avgWaitTime: "56",
          operationalAlert: "25370",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 68,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
        },
      },
    },
    {
      id: "entranceExit09",
      title: "Entrance & Exit #09",
      data: {
        day: {
          totalIncidents: "878",
          occupancy: "25%",
          totalVisitors: "550",
          avgWaitTime: "14",
          operationalAlert: "470",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 16,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
              ],
            },
          ],
        },
        weekly: {
          totalIncidents: "2278",
          occupancy: "43%",
          totalVisitors: "1500",
          avgWaitTime: "23",
          operationalAlert: "1170",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 64,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 60,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 68,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
              ],
            },
          ],
        },
        monthly: {
          totalIncidents: "3278",
          occupancy: "31%",
          totalVisitors: "1100",
          avgWaitTime: "41",
          operationalAlert: "2170",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 63,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
        },
        yearly: {
          totalIncidents: "71278",
          occupancy: "45%",
          totalVisitors: "12500",
          avgWaitTime: "51",
          operationalAlert: "21170",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 63,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 63,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
        },
      },
    },
    {
      id: "exit10",
      title: "Exit #10",
      data: {
        day: {
          totalIncidents: "678",
          occupancy: "43%",
          totalVisitors: "300",
          avgWaitTime: "24",
          operationalAlert: "270",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 60,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 27.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
        },
        weekly: {
          totalIncidents: "3178",
          occupancy: "45%",
          totalVisitors: "255",
          avgWaitTime: "53",
          operationalAlert: "370",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 60,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 71.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 69,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
        },
        monthly: {
          totalIncidents: "7278",
          occupancy: "55%",
          totalVisitors: "2500",
          avgWaitTime: "52",
          operationalAlert: "1270",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 65,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 68,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
              ],
            },
          ],
        },
        yearly: {
          totalIncidents: "72278",
          occupancy: "65%",
          totalVisitors: "13500",
          avgWaitTime: "55",
          operationalAlert: "21170",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
              ],
            },
          ],
        },
      },
    },
    {
      id: "entranceExit11",
      title: "Entrance & Exit #11",
      data: {
        day: {
          totalIncidents: "378",
          occupancy: "30%",
          totalVisitors: "200",
          avgWaitTime: "12",
          operationalAlert: "170",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 71.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 60,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 65,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 71.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 63,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 60,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
              ],
            },
          ],
        },
        weekly: {
          totalIncidents: "378",
          occupancy: "745%",
          totalVisitors: "200",
          avgWaitTime: "45",
          operationalAlert: "390",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
        },
        monthly: {
          totalIncidents: "8278",
          occupancy: "45%",
          totalVisitors: "1500",
          avgWaitTime: "55",
          operationalAlert: "3870",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 63,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 63,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
              ],
            },
          ],
        },
        yearly: {
          totalIncidents: "61278",
          occupancy: "60%",
          totalVisitors: "5500",
          avgWaitTime: "15170",
          operationalAlert: "21170",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 65,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 68,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 71.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 63,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 63,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 65,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 71.0,
                },
              ],
            },
          ],
        },
      },
    },
    {
      id: "entranceExit12",
      title: "Entrance & Exit #12",
      data: {
        day: {
          totalIncidents: "678",
          occupancy: "43%",
          totalVisitors: "300",
          avgWaitTime: "24",
          operationalAlert: "270",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 60,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 27.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
        },
        weekly: {
          totalIncidents: "3178",
          occupancy: "45%",
          totalVisitors: "255",
          avgWaitTime: "53",
          operationalAlert: "370",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 60,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 71.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 69,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
        },
        monthly: {
          totalIncidents: "7278",
          occupancy: "55%",
          totalVisitors: "2500",
          avgWaitTime: "52",
          operationalAlert: "1270",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 65,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 68,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
              ],
            },
          ],
        },
        yearly: {
          totalIncidents: "72278",
          occupancy: "65%",
          totalVisitors: "13500",
          avgWaitTime: "55",
          operationalAlert: "21170",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
              ],
            },
          ],
        },
      },
    },
    {
      id: "entranceExit13",
      title: "Entrance & Exit #13",
      data: {
        day: {
          totalIncidents: "878",
          occupancy: "40%",
          totalVisitors: "280",
          avgWaitTime: "56",
          operationalAlert: "370",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 97.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 96,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
        },
        weekly: {
          totalIncidents: "2278",
          occupancy: "42%",
          totalVisitors: "1210",
          avgWaitTime: "48",
          operationalAlert: "1470",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 63,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 63,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 68,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
        },
        monthly: {
          totalIncidents: "28278",
          occupancy: "55%",
          totalVisitors: "12500",
          avgWaitTime: "58",
          operationalAlert: "13170",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
              ],
            },
          ],
        },
        yearly: {
          totalIncidents: "82278",
          occupancy: "76%",
          totalVisitors: "23100",
          avgWaitTime: "63",
          operationalAlert: "31170",
          occupancyDataEntranceExit: [
            {
              node: "2022-10-11T11:00:00",
              count: 67,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          visitorsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 96,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          avgWaitTimeDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          securityIncidentsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          operationalAlertsDataEntrance: [
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          utilisationData: [
            {
              metricName: "entrance",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 14.0,
                },
              ],
            },
            {
              metricName: "exit",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
              ],
            },
          ],
        },
      },
    },
  ],
};
export default analyticsEntranceExit;
