const analyticsParking = {
  analyticsList: [
    {
      id: "overAll",
      title: "OVERALL ANALYTICS",
      data: {
        day: {
          totalOccupiedSpot: "780",
          totalAvailableSpot: "12",
          totalIncidents: "2780",
          totalOprAlerts: "2780",
          totalHoursSaved: "2780",
          occupancyParking: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 90.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          availability: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          hoursSaved: [
            {
              node: "2022-10-11T11:00:00",
              count: 2.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 3.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 4.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 1.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 3.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 2.0,
            },
          ],
          incidents: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          eventsOprAlerts: [
            {
              metricName: "events",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
              ],
            },
            {
              metricName: "oprAlerts",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
          incidentsBreakdown: [
            {
              title: "WRONG PARKING",
              value: 12,
            },
            {
              title: "TAILGATING",
              value: 13,
            },
            {
              title: "DOUBLE PARKING",
              value: 14,
            },
            {
              title: "ACCIDENT DETECTED",
              value: 15,
            },
            {
              title: "REVERSE PARKING",
              value: 16,
            },
          ],
        },
        weekly: {
          totalOccupiedSpot: "880",
          totalAvailableSpot: "18",
          totalIncidents: "120",
          totalOprAlerts: "80",
          totalHoursSaved: "30",
          occupancyParking: [
            {
              node: "2022-10-11T11:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 70.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 73.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 60.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 72.0,
            },
          ],
          availability: [
            {
              node: "2022-10-11T11:00:00",
              count: 77,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 76.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 44.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 7.0,
            },
          ],
          hoursSaved: [
            {
              node: "2022-10-11T11:00:00",
              count: 31.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 32.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 39.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 33.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 29.0,
            },
          ],
          incidents: [
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 66.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 66.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 36.0,
            },
          ],
          eventsOprAlerts: [
            {
              metricName: "events",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 75.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 69.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 72.0,
                },
              ],
            },
            {
              metricName: "oprAlerts",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 55,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
              ],
            },
          ],
          incidentsBreakdown: [
            {
              title: "WRONG PARKING",
              value: 12,
            },
            {
              title: "TAILGATING",
              value: 11,
            },
            {
              title: "DOUBLE PARKING",
              value: 10,
            },
            {
              title: "ACCIDENT DETECTED",
              value: 9,
            },
            {
              title: "REVERSE PARKING",
              value: 8,
            },
          ],
        },
        monthly: {
          totalOccupiedSpot: "980",
          totalAvailableSpot: "12",
          totalIncidents: "2780",
          totalOprAlerts: "2780",
          totalHoursSaved: "2780",
          occupancyParking: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          availability: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          hoursSaved: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          incidents: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          eventsOprAlerts: [
            {
              metricName: "events",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
              ],
            },
            {
              metricName: "oprAlerts",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
              ],
            },
          ],
          incidentsBreakdown: [
            {
              title: "WRONG PARKING",
              value: 12,
            },
            {
              title: "TAILGATING",
              value: 13,
            },
            {
              title: "DOUBLE PARKING",
              value: 14,
            },
            {
              title: "ACCIDENT DETECTED",
              value: 15,
            },
            {
              title: "REVERSE PARKING",
              value: 16,
            },
          ],
        },
        yearly: {
          totalOccupiedSpot: "1080",
          totalAvailableSpot: "600",
          totalIncidents: "8780",
          totalOprAlerts: "7780",
          totalHoursSaved: "6780",
          occupancyParking: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          availability: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          hoursSaved: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          incidents: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          eventsOprAlerts: [
            {
              metricName: "events",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
              ],
            },
            {
              metricName: "oprAlerts",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
              ],
            },
          ],
          incidentsBreakdown: [
            {
              title: "WRONG PARKING",
              value: 12,
            },
            {
              title: "TAILGATING",
              value: 13,
            },
            {
              title: "DOUBLE PARKING",
              value: 14,
            },
            {
              title: "ACCIDENT DETECTED",
              value: 15,
            },
            {
              title: "REVERSE PARKING",
              value: 16,
            },
          ],
        },
      },
    },
    {
      id: "vip",
      title: "VIP Parking",
      data: {
        day: {
          totalOccupiedSpot: "5000",
          totalAvailableSpot: "12",
          totalIncidents: "2780",
          totalOprAlerts: "2780",
          totalHoursSaved: "2780",
          occupancyParking: [
            {
              node: "2022-10-11T11:00:00",
              count: 70.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 60.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 90.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          availability: [
            {
              node: "2022-10-11T11:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 60.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 50.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 76.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 90.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 50.0,
            },
          ],
          hoursSaved: [
            {
              node: "2022-10-11T11:00:00",
              count: 5.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 1.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 2.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 3.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 1.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 3.0,
            },
          ],
          incidents: [
            {
              node: "2022-10-11T11:00:00",
              count: 60.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 90.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 50.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
          ],
          eventsOprAlerts: [
            {
              metricName: "events",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 50,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 127.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 107.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 90.0,
                },
              ],
            },
            {
              metricName: "oprAlerts",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 90,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 60.0,
                },
              ],
            },
          ],
          incidentsBreakdown: [
            {
              title: "WRONG PARKING",
              value: 10,
            },
            {
              title: "TAILGATING",
              value: 80,
            },
            {
              title: "DOUBLE PARKING",
              value: 13,
            },
            {
              title: "ACCIDENT DETECTED",
              value: 16,
            },
            {
              title: "REVERSE PARKING",
              value: 12,
            },
          ],
        },
        weekly: {
          totalOccupiedSpot: "6780",
          totalAvailableSpot: "700",
          totalIncidents: "3780",
          totalOprAlerts: "4780",
          totalHoursSaved: "5780",
          occupancyParking: [
            {
              node: "2022-10-11T11:00:00",
              count: 70.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 50.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 70.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 60.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 90.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 80.0,
            },
          ],
          availability: [
            {
              node: "2022-10-11T11:00:00",
              count: 90.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 76.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 54.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 40.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
          ],
          hoursSaved: [
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 70.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 56.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 98.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
          ],
          incidents: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 46.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 66.0,
            },
          ],
          eventsOprAlerts: [
            {
              metricName: "events",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 90,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 62.0,
                },
              ],
            },
            {
              metricName: "oprAlerts",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
              ],
            },
          ],
          incidentsBreakdown: [
            {
              title: "WRONG PARKING",
              value: 10,
            },
            {
              title: "TAILGATING",
              value: 13,
            },
            {
              title: "DOUBLE PARKING",
              value: 15,
            },
            {
              title: "ACCIDENT DETECTED",
              value: 17,
            },
            {
              title: "REVERSE PARKING",
              value: 18,
            },
          ],
        },
        monthly: {
          totalOccupiedSpot: "7780",
          totalAvailableSpot: "789",
          totalIncidents: "4780",
          totalOprAlerts: "5780",
          totalHoursSaved: "6780",
          occupancyParking: [
            {
              node: "2022-10-11T11:00:00",
              count: 96.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
          ],
          availability: [
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
          ],
          hoursSaved: [
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
          ],
          incidents: [
            {
              node: "2022-10-11T11:00:00",
              count: 80.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 60.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 59.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
          ],
          eventsOprAlerts: [
            {
              metricName: "events",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 127.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 127.0,
                },
              ],
            },
            {
              metricName: "oprAlerts",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
              ],
            },
          ],
          incidentsBreakdown: [
            {
              title: "WRONG PARKING",
              value: 18,
            },
            {
              title: "TAILGATING",
              value: 10,
            },
            {
              title: "DOUBLE PARKING",
              value: 14,
            },
            {
              title: "ACCIDENT DETECTED",
              value: 13,
            },
            {
              title: "REVERSE PARKING",
              value: 19,
            },
          ],
        },
        yearly: {
          totalOccupiedSpot: "8780",
          totalAvailableSpot: "2340",
          totalIncidents: "4780",
          totalOprAlerts: "7780",
          totalHoursSaved: "8780",
          occupancyParking: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 59.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
          ],
          availability: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 97.0,
            },
          ],
          hoursSaved: [
            {
              node: "2022-10-11T11:00:00",
              count: 40.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 49.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 97.0,
            },
          ],
          incidents: [
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
          ],
          eventsOprAlerts: [
            {
              metricName: "events",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 167.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 90,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "oprAlerts",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 107.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 90,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 106,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
              ],
            },
          ],
          incidentsBreakdown: [
            {
              title: "WRONG PARKING",
              value: 10,
            },
            {
              title: "TAILGATING",
              value: 18,
            },
            {
              title: "DOUBLE PARKING",
              value: 13,
            },
            {
              title: "ACCIDENT DETECTED",
              value: 15,
            },
            {
              title: "REVERSE PARKING",
              value: 14,
            },
          ],
        },
      },
    },
    {
      id: "lotA",
      title: "Lot - A (Employee Parking)",
      data: {
        day: {
          totalOccupiedSpot: "2780",
          totalAvailableSpot: "12",
          totalIncidents: "2780",
          totalOprAlerts: "2780",
          totalHoursSaved: "2780",
          occupancyParking: [
            {
              node: "2022-10-11T11:00:00",
              count: 80.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 50.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 56.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 70.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
          ],
          availability: [
            {
              node: "2022-10-11T11:00:00",
              count: 40.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
          ],
          hoursSaved: [
            {
              node: "2022-10-11T11:00:00",
              count: 2.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 1.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 3.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 2.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 4.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 3.0,
            },
          ],
          incidents: [
            {
              node: "2022-10-11T11:00:00",
              count: 80.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 76.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 97.0,
            },
          ],
          eventsOprAlerts: [
            {
              metricName: "events",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 80,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 187.0,
                },
              ],
            },
            {
              metricName: "oprAlerts",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 56,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 127.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 117.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 107.0,
                },
              ],
            },
          ],
          incidentsBreakdown: [
            {
              title: "WRONG PARKING",
              value: 10,
            },
            {
              title: "TAILGATING",
              value: 17,
            },
            {
              title: "DOUBLE PARKING",
              value: 16,
            },
            {
              title: "ACCIDENT DETECTED",
              value: 12,
            },
            {
              title: "REVERSE PARKING",
              value: 10,
            },
          ],
        },
        weekly: {
          totalOccupiedSpot: "3780",
          totalAvailableSpot: "20",
          totalIncidents: "3780",
          totalOprAlerts: "2780",
          totalHoursSaved: "1780",
          occupancyParking: [
            {
              node: "2022-10-11T11:00:00",
              count: 90.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 49.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
          ],
          availability: [
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 59.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 90.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
          ],
          hoursSaved: [
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
          ],
          incidents: [
            {
              node: "2022-10-11T11:00:00",
              count: 76.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 96.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
          ],
          eventsOprAlerts: [
            {
              metricName: "events",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 117.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 137.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 127.0,
                },
              ],
            },
            {
              metricName: "oprAlerts",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 127.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 167.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 107.0,
                },
              ],
            },
          ],
          incidentsBreakdown: [
            {
              title: "WRONG PARKING",
              value: 19,
            },
            {
              title: "TAILGATING",
              value: 12,
            },
            {
              title: "DOUBLE PARKING",
              value: 15,
            },
            {
              title: "ACCIDENT DETECTED",
              value: 17,
            },
            {
              title: "REVERSE PARKING",
              value: 18,
            },
          ],
        },
        monthly: {
          totalOccupiedSpot: "4780",
          totalAvailableSpot: "30",
          totalIncidents: "4780",
          totalOprAlerts: "3780",
          totalHoursSaved: "4780",
          occupancyParking: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
          ],
          availability: [
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
          ],
          hoursSaved: [
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
          ],
          incidents: [
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 59.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
          ],
          eventsOprAlerts: [
            {
              metricName: "events",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 107.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
              ],
            },
            {
              metricName: "oprAlerts",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 68,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
              ],
            },
          ],
          incidentsBreakdown: [
            {
              title: "WRONG PARKING",
              value: 10,
            },
            {
              title: "TAILGATING",
              value: 11,
            },
            {
              title: "DOUBLE PARKING",
              value: 12,
            },
            {
              title: "ACCIDENT DETECTED",
              value: 14,
            },
            {
              title: "REVERSE PARKING",
              value: 16,
            },
          ],
        },
        yearly: {
          totalOccupiedSpot: "6780",
          totalAvailableSpot: "200",
          totalIncidents: "6780",
          totalOprAlerts: "7780",
          totalHoursSaved: "8780",
          occupancyParking: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 90.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
          ],
          availability: [
            {
              node: "2022-10-11T11:00:00",
              count: 90.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 98.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          hoursSaved: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          incidents: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          eventsOprAlerts: [
            {
              metricName: "events",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
              ],
            },
            {
              metricName: "oprAlerts",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
              ],
            },
          ],
          incidentsBreakdown: [
            {
              title: "WRONG PARKING",
              value: 10,
            },
            {
              title: "TAILGATING",
              value: 11,
            },
            {
              title: "DOUBLE PARKING",
              value: 15,
            },
            {
              title: "ACCIDENT DETECTED",
              value: 15,
            },
            {
              title: "REVERSE PARKING",
              value: 19,
            },
          ],
        },
      },
    },
    {
      id: "lotB",
      title: "Lot - B (VIP Parking)",
      data: {
        day: {
          totalOccupiedSpot: "3680",
          totalAvailableSpot: "18",
          totalIncidents: "2480",
          totalOprAlerts: "3780",
          totalHoursSaved: "4680",
          occupancyParking: [
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 59.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 39.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 97.0,
            },
          ],
          availability: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          hoursSaved: [
            {
              node: "2022-10-11T11:00:00",
              count: 4.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 1.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 2.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 3.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 1.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 2.0,
            },
          ],
          incidents: [
            {
              node: "2022-10-11T11:00:00",
              count: 39.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
          ],
          eventsOprAlerts: [
            {
              metricName: "events",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 107.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 117.0,
                },
              ],
            },
            {
              metricName: "oprAlerts",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
              ],
            },
          ],
          incidentsBreakdown: [
            {
              title: "WRONG PARKING",
              value: 10,
            },
            {
              title: "TAILGATING",
              value: 13,
            },
            {
              title: "DOUBLE PARKING",
              value: 14,
            },
            {
              title: "ACCIDENT DETECTED",
              value: 17,
            },
            {
              title: "REVERSE PARKING",
              value: 18,
            },
          ],
        },
        weekly: {
          totalOccupiedSpot: "4780",
          totalAvailableSpot: "19",
          totalIncidents: "3580",
          totalOprAlerts: "3480",
          totalHoursSaved: "3780",
          occupancyParking: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 49.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
          ],
          availability: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
          ],
          hoursSaved: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          incidents: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
          ],
          eventsOprAlerts: [
            {
              metricName: "events",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 107.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 117.0,
                },
              ],
            },
            {
              metricName: "oprAlerts",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
              ],
            },
          ],
          incidentsBreakdown: [
            {
              title: "WRONG PARKING",
              value: 12,
            },
            {
              title: "TAILGATING",
              value: 13,
            },
            {
              title: "DOUBLE PARKING",
              value: 18,
            },
            {
              title: "ACCIDENT DETECTED",
              value: 19,
            },
            {
              title: "REVERSE PARKING",
              value: 16,
            },
          ],
        },
        monthly: {
          totalOccupiedSpot: "8780",
          totalAvailableSpot: "23",
          totalIncidents: "3680",
          totalOprAlerts: "4380",
          totalHoursSaved: "3280",
          occupancyParking: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
          ],
          availability: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          hoursSaved: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
          ],
          incidents: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 37.0,
            },
          ],
          eventsOprAlerts: [
            {
              metricName: "events",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 137.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 117.0,
                },
              ],
            },
            {
              metricName: "oprAlerts",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 117.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 137.0,
                },
              ],
            },
          ],
          incidentsBreakdown: [
            {
              title: "WRONG PARKING",
              value: 13,
            },
            {
              title: "TAILGATING",
              value: 15,
            },
            {
              title: "DOUBLE PARKING",
              value: 16,
            },
            {
              title: "ACCIDENT DETECTED",
              value: 17,
            },
            {
              title: "REVERSE PARKING",
              value: 18,
            },
          ],
        },
        yearly: {
          totalOccupiedSpot: "3780",
          totalAvailableSpot: "42",
          totalIncidents: "3480",
          totalOprAlerts: "2980",
          totalHoursSaved: "2980",
          occupancyParking: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
          ],
          availability: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 49.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
          ],
          hoursSaved: [
            {
              node: "2022-10-11T11:00:00",
              count: 90.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 59.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
          ],
          incidents: [
            {
              node: "2022-10-11T11:00:00",
              count: 90.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
          ],
          eventsOprAlerts: [
            {
              metricName: "events",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 56,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 117.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 127.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
              ],
            },
            {
              metricName: "oprAlerts",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 117.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 137.0,
                },

                {
                  node: "2022-10-11T11:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
              ],
            },
          ],
          incidentsBreakdown: [
            {
              title: "WRONG PARKING",
              value: 19,
            },
            {
              title: "TAILGATING",
              value: 15,
            },
            {
              title: "DOUBLE PARKING",
              value: 18,
            },
            {
              title: "ACCIDENT DETECTED",
              value: 17,
            },
            {
              title: "REVERSE PARKING",
              value: 16,
            },
          ],
        },
      },
    },
    {
      id: "lotC",
      title: "Lot - C (General Parking)",
      data: {
        day: {
          totalOccupiedSpot: "3780",
          totalAvailableSpot: "80",
          totalIncidents: "2980",
          totalOprAlerts: "2680",
          totalHoursSaved: "2380",
          occupancyParking: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
          ],
          availability: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 59.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
          ],
          hoursSaved: [
            {
              node: "2022-10-11T11:00:00",
              count: 3.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 2.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 1.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 2.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 4.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 3.0,
            },
          ],
          incidents: [
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 90.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 49.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
          ],
          eventsOprAlerts: [
            {
              metricName: "events",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "oprAlerts",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 87,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
              ],
            },
          ],
          incidentsBreakdown: [
            {
              title: "WRONG PARKING",
              value: 19,
            },
            {
              title: "TAILGATING",
              value: 17,
            },
            {
              title: "DOUBLE PARKING",
              value: 24,
            },
            {
              title: "ACCIDENT DETECTED",
              value: 15,
            },
            {
              title: "REVERSE PARKING",
              value: 16,
            },
          ],
        },
        weekly: {
          totalOccupiedSpot: "4780",
          totalAvailableSpot: "42",
          totalIncidents: "3780",
          totalOprAlerts: "4780",
          totalHoursSaved: "5780",
          occupancyParking: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
          ],
          availability: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
          ],
          hoursSaved: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 97.0,
            },
          ],
          incidents: [
            {
              node: "2022-10-11T11:00:00",
              count: 90.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 76.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
          ],
          eventsOprAlerts: [
            {
              metricName: "events",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
              ],
            },
            {
              metricName: "oprAlerts",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
              ],
            },
          ],
          incidentsBreakdown: [
            {
              title: "WRONG PARKING",
              value: 10,
            },
            {
              title: "TAILGATING",
              value: 13,
            },
            {
              title: "DOUBLE PARKING",
              value: 14,
            },
            {
              title: "ACCIDENT DETECTED",
              value: 19,
            },
            {
              title: "REVERSE PARKING",
              value: 17,
            },
          ],
        },
        monthly: {
          totalOccupiedSpot: "6780",
          totalAvailableSpot: "300",
          totalIncidents: "6780",
          totalOprAlerts: "7780",
          totalHoursSaved: "8780",
          occupancyParking: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          availability: [
            {
              node: "2022-10-11T11:00:00",
              count: 80.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 68.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 80.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 68.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 80.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 68.0,
            },
          ],
          hoursSaved: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
          ],
          incidents: [
            {
              node: "2022-10-11T11:00:00",
              count: 49.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 49.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 49.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
          ],
          eventsOprAlerts: [
            {
              metricName: "events",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
              ],
            },
            {
              metricName: "oprAlerts",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
              ],
            },
          ],
          incidentsBreakdown: [
            {
              title: "WRONG PARKING",
              value: 11,
            },
            {
              title: "TAILGATING",
              value: 14,
            },
            {
              title: "DOUBLE PARKING",
              value: 16,
            },
            {
              title: "ACCIDENT DETECTED",
              value: 17,
            },
            {
              title: "REVERSE PARKING",
              value: 16,
            },
          ],
        },
        yearly: {
          totalOccupiedSpot: "7780",
          totalAvailableSpot: "400",
          totalIncidents: "8780",
          totalOprAlerts: "9780",
          totalHoursSaved: "9880",
          occupancyParking: [
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
          ],
          availability: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
          ],
          hoursSaved: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
          ],
          incidents: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
          ],
          eventsOprAlerts: [
            {
              metricName: "events",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
              ],
            },
            {
              metricName: "oprAlerts",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
              ],
            },
          ],
          incidentsBreakdown: [
            {
              title: "WRONG PARKING",
              value: 19,
            },
            {
              title: "TAILGATING",
              value: 18,
            },
            {
              title: "DOUBLE PARKING",
              value: 14,
            },
            {
              title: "ACCIDENT DETECTED",
              value: 12,
            },
            {
              title: "REVERSE PARKING",
              value: 16,
            },
          ],
        },
      },
    },
    {
      id: "lotD",
      title: "Lot - D (General Parking)",
      data: {
        day: {
          totalOccupiedSpot: "3780",
          totalAvailableSpot: "60",
          totalIncidents: "2680",
          totalOprAlerts: "2880",
          totalHoursSaved: "2980",
          occupancyParking: [
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
          ],
          availability: [
            {
              node: "2022-10-11T11:00:00",
              count: 78.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 78.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 78.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 37.0,
            },
          ],
          hoursSaved: [
            {
              node: "2022-10-11T11:00:00",
              count: 4.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 1.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 2.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 3.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 1.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 2.0,
            },
          ],
          incidents: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
          ],
          eventsOprAlerts: [
            {
              metricName: "events",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "oprAlerts",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
              ],
            },
          ],
          incidentsBreakdown: [
            {
              title: "WRONG PARKING",
              value: 18,
            },
            {
              title: "TAILGATING",
              value: 13,
            },
            {
              title: "DOUBLE PARKING",
              value: 17,
            },
            {
              title: "ACCIDENT DETECTED",
              value: 15,
            },
            {
              title: "REVERSE PARKING",
              value: 18,
            },
          ],
        },
        weekly: {
          totalOccupiedSpot: "5780",
          totalAvailableSpot: "790",
          totalIncidents: "4780",
          totalOprAlerts: "5780",
          totalHoursSaved: "6780",
          occupancyParking: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          availability: [
            {
              node: "2022-10-11T11:00:00",
              count: 59.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 59.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 59.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 97.0,
            },
          ],
          hoursSaved: [
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 97.0,
            },
          ],
          incidents: [
            {
              node: "2022-10-11T11:00:00",
              count: 59.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 59.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 59.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
          ],
          eventsOprAlerts: [
            {
              metricName: "events",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
              ],
            },
            {
              metricName: "oprAlerts",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
              ],
            },
          ],
          incidentsBreakdown: [
            {
              title: "WRONG PARKING",
              value: 10,
            },
            {
              title: "TAILGATING",
              value: 13,
            },
            {
              title: "DOUBLE PARKING",
              value: 14,
            },
            {
              title: "ACCIDENT DETECTED",
              value: 14,
            },
            {
              title: "REVERSE PARKING",
              value: 16,
            },
          ],
        },
        monthly: {
          totalOccupiedSpot: "7780",
          totalAvailableSpot: "1964",
          totalIncidents: "7780",
          totalOprAlerts: "8780",
          totalHoursSaved: "9780",
          occupancyParking: [
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          availability: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          hoursSaved: [
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          incidents: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
          ],
          eventsOprAlerts: [
            {
              metricName: "events",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
              ],
            },
            {
              metricName: "oprAlerts",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
              ],
            },
          ],
          incidentsBreakdown: [
            {
              title: "WRONG PARKING",
              value: 18,
            },
            {
              title: "TAILGATING",
              value: 13,
            },
            {
              title: "DOUBLE PARKING",
              value: 16,
            },
            {
              title: "ACCIDENT DETECTED",
              value: 15,
            },
            {
              title: "REVERSE PARKING",
              value: 16,
            },
          ],
        },
        yearly: {
          totalOccupiedSpot: "9780",
          totalAvailableSpot: "2287",
          totalIncidents: "8780",
          totalOprAlerts: "9780",
          totalHoursSaved: "9780",
          occupancyParking: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          availability: [
            {
              node: "2022-10-11T11:00:00",
              count: 59.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 59.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 59.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 97.0,
            },
          ],
          hoursSaved: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
          ],
          incidents: [
            {
              node: "2022-10-11T11:00:00",
              count: 59.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 59.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 59.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
          ],
          eventsOprAlerts: [
            {
              metricName: "events",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
              ],
            },
            {
              metricName: "oprAlerts",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 67.0,
                },
              ],
            },
          ],
          incidentsBreakdown: [
            {
              title: "WRONG PARKING",
              value: 10,
            },
            {
              title: "TAILGATING",
              value: 13,
            },
            {
              title: "DOUBLE PARKING",
              value: 14,
            },
            {
              title: "ACCIDENT DETECTED",
              value: 19,
            },
            {
              title: "REVERSE PARKING",
              value: 14,
            },
          ],
        },
      },
    },
    {
      id: "lotE",
      title: "Lot - E (General Parking)",
      data: {
        day: {
          totalOccupiedSpot: "3780",
          totalAvailableSpot: "25",
          totalIncidents: "3780",
          totalOprAlerts: "3600",
          totalHoursSaved: "4780",
          occupancyParking: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
          ],
          availability: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          hoursSaved: [
            {
              node: "2022-10-11T11:00:00",
              count: 5.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 1.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 2.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 1.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 3.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 4.0,
            },
          ],
          incidents: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 30.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 30.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 30.0,
            },
          ],
          eventsOprAlerts: [
            {
              metricName: "events",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "oprAlerts",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
          incidentsBreakdown: [
            {
              title: "WRONG PARKING",
              value: 12,
            },
            {
              title: "TAILGATING",
              value: 13,
            },
            {
              title: "DOUBLE PARKING",
              value: 13,
            },
            {
              title: "ACCIDENT DETECTED",
              value: 14,
            },
            {
              title: "REVERSE PARKING",
              value: 16,
            },
          ],
        },
        weekly: {
          totalOccupiedSpot: "7780",
          totalAvailableSpot: "672",
          totalIncidents: "6780",
          totalOprAlerts: "6780",
          totalHoursSaved: "5780",
          occupancyParking: [
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          availability: [
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
          ],
          hoursSaved: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          incidents: [
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 37.0,
            },
          ],
          eventsOprAlerts: [
            {
              metricName: "events",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "oprAlerts",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
              ],
            },
          ],
          incidentsBreakdown: [
            {
              title: "WRONG PARKING",
              value: 12,
            },
            {
              title: "TAILGATING",
              value: 13,
            },
            {
              title: "DOUBLE PARKING",
              value: 14,
            },
            {
              title: "ACCIDENT DETECTED",
              value: 15,
            },
            {
              title: "REVERSE PARKING",
              value: 16,
            },
          ],
        },
        monthly: {
          totalOccupiedSpot: "7780",
          totalAvailableSpot: "1782",
          totalIncidents: "8780",
          totalOprAlerts: "9780",
          totalHoursSaved: "8780",
          occupancyParking: [
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
          ],
          availability: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          hoursSaved: [
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 97.0,
            },
          ],
          incidents: [
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          eventsOprAlerts: [
            {
              metricName: "events",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "oprAlerts",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
              ],
            },
          ],
          incidentsBreakdown: [
            {
              title: "WRONG PARKING",
              value: 16,
            },
            {
              title: "TAILGATING",
              value: 13,
            },
            {
              title: "DOUBLE PARKING",
              value: 11,
            },
            {
              title: "ACCIDENT DETECTED",
              value: 15,
            },
            {
              title: "REVERSE PARKING",
              value: 16,
            },
          ],
        },
        yearly: {
          totalOccupiedSpot: "9780",
          totalAvailableSpot: "1662",
          totalIncidents: "8780",
          totalOprAlerts: "8780",
          totalHoursSaved: "9780",
          occupancyParking: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          availability: [
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          hoursSaved: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          incidents: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 37.0,
            },
          ],
          eventsOprAlerts: [
            {
              metricName: "events",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "oprAlerts",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
              ],
            },
          ],
          incidentsBreakdown: [
            {
              title: "WRONG PARKING",
              value: 10,
            },
            {
              title: "TAILGATING",
              value: 11,
            },
            {
              title: "DOUBLE PARKING",
              value: 12,
            },
            {
              title: "ACCIDENT DETECTED",
              value: 15,
            },
            {
              title: "REVERSE PARKING",
              value: 16,
            },
          ],
        },
      },
    },
    {
      id: "lotF",
      title: "Lot - F (General Parking)",
      data: {
        day: {
          totalOccupiedSpot: "2580",
          totalAvailableSpot: "12",
          totalIncidents: "2280",
          totalOprAlerts: "2580",
          totalHoursSaved: "2380",
          occupancyParking: [
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
          ],
          availability: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          hoursSaved: [
            {
              node: "2022-10-11T11:00:00",
              count: 1.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 2.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 3.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 1.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 4.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 1.0,
            },
          ],
          incidents: [
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 37.0,
            },
          ],
          eventsOprAlerts: [
            {
              metricName: "events",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
              ],
            },
            {
              metricName: "oprAlerts",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
              ],
            },
          ],
          incidentsBreakdown: [
            {
              title: "WRONG PARKING",
              value: 11,
            },
            {
              title: "TAILGATING",
              value: 15,
            },
            {
              title: "DOUBLE PARKING",
              value: 17,
            },
            {
              title: "ACCIDENT DETECTED",
              value: 15,
            },
            {
              title: "REVERSE PARKING",
              value: 16,
            },
          ],
        },
        weekly: {
          totalOccupiedSpot: "3780",
          totalAvailableSpot: "438",
          totalIncidents: "3780",
          totalOprAlerts: "4780",
          totalHoursSaved: "5780",
          occupancyParking: [
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 37.0,
            },
          ],
          availability: [
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 27.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 27.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 27.0,
            },
          ],
          hoursSaved: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
          ],
          incidents: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          eventsOprAlerts: [
            {
              metricName: "events",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
              ],
            },
            {
              metricName: "oprAlerts",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
          incidentsBreakdown: [
            {
              title: "WRONG PARKING",
              value: 12,
            },
            {
              title: "TAILGATING",
              value: 13,
            },
            {
              title: "DOUBLE PARKING",
              value: 14,
            },
            {
              title: "ACCIDENT DETECTED",
              value: 12,
            },
            {
              title: "REVERSE PARKING",
              value: 10,
            },
          ],
        },
        monthly: {
          totalOccupiedSpot: "4780",
          totalAvailableSpot: "1882",
          totalIncidents: "5780",
          totalOprAlerts: "6780",
          totalHoursSaved: "8780",
          occupancyParking: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
          ],
          availability: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          hoursSaved: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          incidents: [
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          eventsOprAlerts: [
            {
              metricName: "events",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "oprAlerts",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
          incidentsBreakdown: [
            {
              title: "WRONG PARKING",
              value: 12,
            },
            {
              title: "TAILGATING",
              value: 13,
            },
            {
              title: "DOUBLE PARKING",
              value: 14,
            },
            {
              title: "ACCIDENT DETECTED",
              value: 15,
            },
            {
              title: "REVERSE PARKING",
              value: 16,
            },
          ],
        },
        yearly: {
          totalOccupiedSpot: "8780",
          totalAvailableSpot: "17762",
          totalIncidents: "9780",
          totalOprAlerts: "8780",
          totalHoursSaved: "9780",
          occupancyParking: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 27.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 27.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 27.0,
            },
          ],
          availability: [
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 37.0,
            },
          ],
          hoursSaved: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          incidents: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          eventsOprAlerts: [
            {
              metricName: "events",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "oprAlerts",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
          incidentsBreakdown: [
            {
              title: "WRONG PARKING",
              value: 12,
            },
            {
              title: "TAILGATING",
              value: 15,
            },
            {
              title: "DOUBLE PARKING",
              value: 16,
            },
            {
              title: "ACCIDENT DETECTED",
              value: 18,
            },
            {
              title: "REVERSE PARKING",
              value: 16,
            },
          ],
        },
      },
    },
    {
      id: "lotG",
      title: "Lot - G (Front Office Parking)",
      data: {
        day: {
          totalOccupiedSpot: "3780",
          totalAvailableSpot: "62",
          totalIncidents: "3780",
          totalOprAlerts: "4780",
          totalHoursSaved: "5780",
          occupancyParking: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 59.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
          ],
          availability: [
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 27.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 27.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 27.0,
            },
          ],
          hoursSaved: [
            {
              node: "2022-10-11T11:00:00",
              count: 3.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 1.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 3.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 2.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 4.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 1.0,
            },
          ],
          incidents: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          eventsOprAlerts: [
            {
              metricName: "events",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 75.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 27.0,
                },
              ],
            },
            {
              metricName: "oprAlerts",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
              ],
            },
          ],
          incidentsBreakdown: [
            {
              title: "WRONG PARKING",
              value: 11,
            },
            {
              title: "TAILGATING",
              value: 13,
            },
            {
              title: "DOUBLE PARKING",
              value: 14,
            },
            {
              title: "ACCIDENT DETECTED",
              value: 16,
            },
            {
              title: "REVERSE PARKING",
              value: 16,
            },
          ],
        },
        weekly: {
          totalOccupiedSpot: "6780",
          totalAvailableSpot: "1782",
          totalIncidents: "7780",
          totalOprAlerts: "8780",
          totalHoursSaved: "9780",
          occupancyParking: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 6.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          availability: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          hoursSaved: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 37.0,
            },
          ],
          incidents: [
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 97.0,
            },
          ],
          eventsOprAlerts: [
            {
              metricName: "events",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
              ],
            },
            {
              metricName: "oprAlerts",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 67.0,
                },
              ],
            },
          ],
          incidentsBreakdown: [
            {
              title: "WRONG PARKING",
              value: 18,
            },
            {
              title: "TAILGATING",
              value: 13,
            },
            {
              title: "DOUBLE PARKING",
              value: 14,
            },
            {
              title: "ACCIDENT DETECTED",
              value: 15,
            },
            {
              title: "REVERSE PARKING",
              value: 11,
            },
          ],
        },
        monthly: {
          totalOccupiedSpot: "7780",
          totalAvailableSpot: "7612",
          totalIncidents: "8780",
          totalOprAlerts: "8780",
          totalHoursSaved: "9780",
          occupancyParking: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          availability: [
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 37.0,
            },
          ],
          hoursSaved: [
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          incidents: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          eventsOprAlerts: [
            {
              metricName: "events",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
              ],
            },
            {
              metricName: "oprAlerts",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 37.0,
                },
              ],
            },
          ],
          incidentsBreakdown: [
            {
              title: "WRONG PARKING",
              value: 12,
            },
            {
              title: "TAILGATING",
              value: 11,
            },
            {
              title: "DOUBLE PARKING",
              value: 14,
            },
            {
              title: "ACCIDENT DETECTED",
              value: 15,
            },
            {
              title: "REVERSE PARKING",
              value: 18,
            },
          ],
        },
        yearly: {
          totalOccupiedSpot: "8780",
          totalAvailableSpot: "1772",
          totalIncidents: "8780",
          totalOprAlerts: "9780",
          totalHoursSaved: "9780",
          occupancyParking: [
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          availability: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 27.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 27.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 27.0,
            },
          ],
          hoursSaved: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          incidents: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          eventsOprAlerts: [
            {
              metricName: "events",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "oprAlerts",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
              ],
            },
          ],
          incidentsBreakdown: [
            {
              title: "WRONG PARKING",
              value: 19,
            },
            {
              title: "TAILGATING",
              value: 13,
            },
            {
              title: "DOUBLE PARKING",
              value: 14,
            },
            {
              title: "ACCIDENT DETECTED",
              value: 16,
            },
            {
              title: "REVERSE PARKING",
              value: 16,
            },
          ],
        },
      },
    },
    {
      id: "busLot",
      title: "Bus Lot",
      data: {
        day: {
          totalOccupiedSpot: "2780",
          totalAvailableSpot: "12",
          totalIncidents: "2780",
          totalOprAlerts: "2780",
          totalHoursSaved: "2780",
          occupancyParking: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          availability: [
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
          ],
          hoursSaved: [
            {
              node: "2022-10-11T11:00:00",
              count: 2.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 1.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 4.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 2.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 1.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 3.0,
            },
          ],
          incidents: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 27.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 27.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 27.0,
            },
          ],
          eventsOprAlerts: [
            {
              metricName: "events",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "oprAlerts",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
              ],
            },
          ],
          incidentsBreakdown: [
            {
              title: "WRONG PARKING",
              value: 12,
            },
            {
              title: "TAILGATING",
              value: 15,
            },
            {
              title: "DOUBLE PARKING",
              value: 18,
            },
            {
              title: "ACCIDENT DETECTED",
              value: 15,
            },
            {
              title: "REVERSE PARKING",
              value: 16,
            },
          ],
        },
        weekly: {
          totalOccupiedSpot: "7780",
          totalAvailableSpot: "1772",
          totalIncidents: "6780",
          totalOprAlerts: "6780",
          totalHoursSaved: "7780",
          occupancyParking: [
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
          ],
          availability: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
          ],
          hoursSaved: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          incidents: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 37.0,
            },
          ],
          eventsOprAlerts: [
            {
              metricName: "events",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "oprAlerts",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
          incidentsBreakdown: [
            {
              title: "WRONG PARKING",
              value: 12,
            },
            {
              title: "TAILGATING",
              value: 13,
            },
            {
              title: "DOUBLE PARKING",
              value: 14,
            },
            {
              title: "ACCIDENT DETECTED",
              value: 15,
            },
            {
              title: "REVERSE PARKING",
              value: 16,
            },
          ],
        },
        monthly: {
          totalOccupiedSpot: "7780",
          totalAvailableSpot: "7812",
          totalIncidents: "8780",
          totalOprAlerts: "8980",
          totalHoursSaved: "9980",
          occupancyParking: [
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
          ],
          availability: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          hoursSaved: [
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 27.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 27.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 27.0,
            },
          ],
          incidents: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          eventsOprAlerts: [
            {
              metricName: "events",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
              ],
            },
            {
              metricName: "oprAlerts",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
              ],
            },
          ],
          incidentsBreakdown: [
            {
              title: "WRONG PARKING",
              value: 12,
            },
            {
              title: "TAILGATING",
              value: 13,
            },
            {
              title: "DOUBLE PARKING",
              value: 14,
            },
            {
              title: "ACCIDENT DETECTED",
              value: 15,
            },
            {
              title: "REVERSE PARKING",
              value: 16,
            },
          ],
        },
        yearly: {
          totalOccupiedSpot: "9780",
          totalAvailableSpot: "1962",
          totalIncidents: "9780",
          totalOprAlerts: "9780",
          totalHoursSaved: "9780",
          occupancyParking: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 37.0,
            },
          ],
          availability: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
          ],
          hoursSaved: [
            {
              node: "2022-10-11T11:00:00",
              count: 49.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 49.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 49.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
          ],
          incidents: [
            {
              node: "2022-10-11T11:00:00",
              count: 39.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 39.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 39.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
          ],
          eventsOprAlerts: [
            {
              metricName: "events",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
              ],
            },
            {
              metricName: "oprAlerts",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
          incidentsBreakdown: [
            {
              title: "WRONG PARKING",
              value: 11,
            },
            {
              title: "TAILGATING",
              value: 13,
            },
            {
              title: "DOUBLE PARKING",
              value: 16,
            },
            {
              title: "ACCIDENT DETECTED",
              value: 15,
            },
            {
              title: "REVERSE PARKING",
              value: 18,
            },
          ],
        },
      },
    },
  ],
};
export default analyticsParking;
