const analyticsRestrooms = {
  analyticsList: [
    {
      id: "overAll",
      title: "OVERALL ANALYTICS",
      data: {
        day: {
          totalIncidents: "278",
          occupancy: "50%",
          sanitationLevel: "80%",
          avgWaitTime: "70",
          operationalAlert: "70",
          occupancyRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 80,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 28.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 77.0,
            },
          ],
          sanitationLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          avgWaitTimeData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 96,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 37.0,
            },
          ],
          airQualityIndexDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 37.0,
            },
          ],
          operationalAlertsDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 96,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 57.0,
            },
          ],
          incidentsRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 97.0,
            },
          ],
        },
        weekly: {
          totalIncidents: "1878",
          occupancy: "60%",
          sanitationLevel: "70%",
          avgWaitTime: "60",
          operationalAlert: "540",
          occupancyRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 80,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 28.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 77.0,
            },
          ],
          sanitationLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 96,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 86,
            },
          ],
          avgWaitTimeData: [
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 97.0,
            },
          ],
          airQualityIndexDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 84.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 72.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          operationalAlertsDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 82.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 70.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 80.0,
            },
          ],
          incidentsRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 84.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 72.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
        },
        monthly: {
          totalIncidents: "5278",
          occupancy: "70%",
          sanitationLevel: "40%",
          avgWaitTime: "60",
          operationalAlert: "80",

          occupancyRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 80,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 28.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 27,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 77.0,
            },
          ],
          sanitationLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 77.0,
            },
          ],
          avgWaitTimeData: [
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 87.0,
            },
          ],
          airQualityIndexDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 87.0,
            },
          ],
          operationalAlertsDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 67.0,
            },
          ],
          incidentsRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 147.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 147.0,
            },
          ],
        },
        yearly: {
          totalIncidents: "22178",
          occupancy: "65%",
          sanitationLevel: "60%",
          avgWaitTime: "65",
          operationalAlert: "12170",
          occupancyRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 80,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 28.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 27,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 97.0,
            },
          ],
          sanitationLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          avgWaitTimeData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 147.0,
            },
          ],
          airQualityIndexDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 147.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 147.0,
            },
          ],
          operationalAlertsDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 147.0,
            },
          ],
          incidentsRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
        },
      },
    },
    {
      id: "restroom#01",
      title: "Restroom #01",
      data: {
        day: {
          totalIncidents: "278",
          occupancy: "50%",
          sanitationLevel: "80%",
          avgWaitTime: "70",
          operationalAlert: "2170",
          occupancyRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          sanitationLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          avgWaitTimeData: [
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          airQualityIndexDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          operationalAlertsDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          incidentsRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 147.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 147.0,
            },
          ],
        },
        weekly: {
          totalIncidents: "6278",
          occupancy: "43%",
          sanitationLevel: "67%",
          avgWaitTime: "70",
          operationalAlert: "1370",
          occupancyRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          sanitationLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          avgWaitTimeData: [
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          airQualityIndexDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          operationalAlertsDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 147.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          incidentsRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
        },
        monthly: {
          totalIncidents: "12278",
          occupancy: "54%",
          sanitationLevel: "65%",
          avgWaitTime: "70",
          operationalAlert: "16170",
          occupancyRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 96,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          sanitationLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          avgWaitTimeData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          airQualityIndexDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          operationalAlertsDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          incidentsRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 147.0,
            },
          ],
        },
        yearly: {
          totalIncidents: "66278",
          occupancy: "50%",
          sanitationLevel: "67%",
          avgWaitTime: "70",
          operationalAlert: "22370",
          occupancyRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          sanitationLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          avgWaitTimeData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 96,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          airQualityIndexDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 96,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          operationalAlertsDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          incidentsRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 147.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 147.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 147.0,
            },
          ],
        },
      },
    },
    {
      id: "restroom#02",
      title: "Restroom #02",
      data: {
        day: {
          totalIncidents: "678",
          occupancy: "30%",
          sanitationLevel: "50%",
          avgWaitTime: "43",
          operationalAlert: "370",
          occupancyRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          sanitationLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 77.0,
            },
          ],
          avgWaitTimeData: [
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 96,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          airQualityIndexDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          operationalAlertsDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 71,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 27.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 23,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          incidentsRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
        },
        weekly: {
          totalIncidents: "2278",
          occupancy: "60%",
          sanitationLevel: "70%",
          avgWaitTime: "54",
          operationalAlert: "1270",
          occupancyRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          sanitationLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          avgWaitTimeData: [
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 77.0,
            },
          ],
          airQualityIndexDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          operationalAlertsDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 96,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          incidentsRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
        },
        monthly: {
          totalIncidents: "12278",
          occupancy: "50%",
          sanitationLevel: "60%",
          avgWaitTime: "70",
          operationalAlert: "1270",
          occupancyRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 96,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          sanitationLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          avgWaitTimeData: [
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          airQualityIndexDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 96,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          operationalAlertsDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          incidentsRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
        },
        yearly: {
          totalIncidents: "23278",
          occupancy: "60%",
          sanitationLevel: "40%",
          avgWaitTime: "40",
          operationalAlert: "12370",
          occupancyRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 96,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          sanitationLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 56.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 87.0,
            },
          ],
          avgWaitTimeData: [
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 54.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          airQualityIndexDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 77.0,
            },
          ],
          operationalAlertsDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 96,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 96,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          incidentsRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 77.0,
            },
          ],
        },
      },
    },
    {
      id: "restroom#03",
      title: "Restroom #03",
      data: {
        day: {
          totalIncidents: "788",
          occupancy: "20%",
          sanitationLevel: "30%",
          avgWaitTime: "50",
          operationalAlert: "370",
          occupancyRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 23.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          sanitationLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          avgWaitTimeData: [
            {
              node: "2022-10-11T11:00:00",
              count: 33,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 44.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 66.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 87.0,
            },
          ],
          airQualityIndexDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 96,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          operationalAlertsDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          incidentsRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
        },
        weekly: {
          totalIncidents: "5278",
          occupancy: "40%",
          sanitationLevel: "60%",
          avgWaitTime: "54",
          operationalAlert: "1370",
          occupancyRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          sanitationLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 27.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          avgWaitTimeData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 23.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          airQualityIndexDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          operationalAlertsDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          incidentsRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 27.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
        },
        monthly: {
          totalIncidents: "23278",
          occupancy: "30%",
          sanitationLevel: "50%",
          avgWaitTime: "45",
          operationalAlert: "3570",
          occupancyRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 147.0,
            },
          ],
          sanitationLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 147.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 147.0,
            },
          ],
          avgWaitTimeData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          airQualityIndexDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 147.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 147.0,
            },
          ],
          operationalAlertsDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 70.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          incidentsRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 96,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 147.0,
            },
          ],
        },
        yearly: {
          totalIncidents: "761278",
          occupancy: "58%",
          sanitationLevel: "70%",
          avgWaitTime: "70",
          operationalAlert: "10370",
          occupancyRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 96,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          sanitationLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 97.0,
            },
          ],
          avgWaitTimeData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 147.0,
            },
          ],
          airQualityIndexDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 147.0,
            },
          ],
          operationalAlertsDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          incidentsRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 147.0,
            },
          ],
        },
      },
    },
    {
      id: "restroom#04",
      title: "Restroom #04",
      data: {
        day: {
          totalIncidents: "878",
          occupancy: "50%",
          sanitationLevel: "40%",
          avgWaitTime: "70",
          operationalAlert: "1070",
          occupancyRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 147.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 87.0,
            },
          ],
          sanitationLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 77.0,
            },
          ],
          avgWaitTimeData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          airQualityIndexDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          operationalAlertsDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          incidentsRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 67.0,
            },
          ],
        },
        weekly: {
          totalIncidents: "278",
          occupancy: "50%",
          sanitationLevel: "65%",
          avgWaitTime: "70",
          operationalAlert: "1270",
          occupancyRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          sanitationLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          avgWaitTimeData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 72.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 34.0,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          airQualityIndexDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 34,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          operationalAlertsDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          incidentsRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 147.0,
            },
          ],
        },
        monthly: {
          totalIncidents: "8778",
          occupancy: "50%",
          sanitationLevel: "45%",
          avgWaitTime: "50",
          operationalAlert: "5110",
          occupancyRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          sanitationLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          avgWaitTimeData: [
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          airQualityIndexDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 16,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          operationalAlertsDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          incidentsRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
        },
        yearly: {
          totalIncidents: "67278",
          occupancy: "54%",
          sanitationLevel: "65%",
          avgWaitTime: "70",
          operationalAlert: "5170",
          occupancyRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          sanitationLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 77.0,
            },
          ],
          avgWaitTimeData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          airQualityIndexDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          operationalAlertsDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          incidentsRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
        },
      },
    },
    {
      id: "restroom#05",
      title: "Restroom #05",
      data: {
        day: {
          totalIncidents: "872",
          occupancy: "54%",
          sanitationLevel: "34%",
          avgWaitTime: "65",
          operationalAlert: "329",
          occupancyRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 61.0,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          sanitationLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 29,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          avgWaitTimeData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 64,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          airQualityIndexDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 41,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          operationalAlertsDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          incidentsRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
        },
        weekly: {
          totalIncidents: "5118",
          occupancy: "43%",
          sanitationLevel: "65%",
          avgWaitTime: "62",
          operationalAlert: "3211",
          occupancyRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 15,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 34,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          sanitationLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 61,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          avgWaitTimeData: [
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          airQualityIndexDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          operationalAlertsDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 63,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 28,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          incidentsRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
        },
        monthly: {
          totalIncidents: "5216",
          occupancy: "45%",
          sanitationLevel: "50%",
          avgWaitTime: "74",
          operationalAlert: "1231",
          occupancyRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          sanitationLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          avgWaitTimeData: [
            {
              node: "2022-10-11T11:00:00",
              count: 96,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          airQualityIndexDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          operationalAlertsDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          incidentsRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
        },
        yearly: {
          totalIncidents: "67812",
          occupancy: "34%",
          sanitationLevel: "54%",
          avgWaitTime: "67",
          operationalAlert: "13411",
          occupancyRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 63,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          sanitationLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          avgWaitTimeData: [
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          airQualityIndexDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          operationalAlertsDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          incidentsRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
        },
      },
    },
    {
      id: "restroom#06",
      title: "Restroom #06",
      data: {
        day: {
          totalIncidents: "678",
          occupancy: "43%",
          sanitationLevel: "45%",
          avgWaitTime: "65",
          operationalAlert: "450",
          occupancyRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          sanitationLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          avgWaitTimeData: [
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          airQualityIndexDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          operationalAlertsDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 23.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          incidentsRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
        },
        weekly: {
          totalIncidents: "678",
          occupancy: "45%",
          sanitationLevel: "43%",
          avgWaitTime: "43",
          operationalAlert: "321",
          occupancyRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 26,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          sanitationLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          avgWaitTimeData: [
            {
              node: "2022-10-11T11:00:00",
              count: 60,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          airQualityIndexDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          operationalAlertsDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          incidentsRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
        },
        monthly: {
          totalIncidents: "7278",
          occupancy: "45%",
          sanitationLevel: "65%",
          avgWaitTime: "45",
          operationalAlert: "3170",
          occupancyRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          sanitationLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          avgWaitTimeData: [
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          airQualityIndexDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          operationalAlertsDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          incidentsRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
        },
        yearly: {
          totalIncidents: "65278",
          occupancy: "54%",
          sanitationLevel: "60%",
          avgWaitTime: "80",
          operationalAlert: "34170",
          occupancyRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          sanitationLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          avgWaitTimeData: [
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          airQualityIndexDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 64,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          operationalAlertsDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          incidentsRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
        },
      },
    },
    {
      id: "restroom#07",
      title: "Restroom #07",
      data: {
        day: {
          totalIncidents: "878",
          occupancy: "30%",
          sanitationLevel: "45%",
          avgWaitTime: "40",
          operationalAlert: "240",
          occupancyRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 76.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          sanitationLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          avgWaitTimeData: [
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          airQualityIndexDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          operationalAlertsDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          incidentsRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
        },
        weekly: {
          totalIncidents: "4278",
          occupancy: "45%",
          sanitationLevel: "76%",
          avgWaitTime: "80",
          operationalAlert: "2370",
          occupancyRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          sanitationLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          avgWaitTimeData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          airQualityIndexDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          operationalAlertsDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          incidentsRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
        },
        monthly: {
          totalIncidents: "6278",
          occupancy: "45%",
          sanitationLevel: "65%",
          avgWaitTime: "61",
          operationalAlert: "4370",
          occupancyRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          sanitationLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 96,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          avgWaitTimeData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 36,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          airQualityIndexDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          operationalAlertsDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          incidentsRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 96,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
        },
        yearly: {
          totalIncidents: "76278",
          occupancy: "35%",
          sanitationLevel: "65%",
          avgWaitTime: "54",
          operationalAlert: "26512",
          occupancyRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          sanitationLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          avgWaitTimeData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          airQualityIndexDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          operationalAlertsDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          incidentsRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
        },
      },
    },
    {
      id: "restroom#08",
      title: "Restroom #08",
      data: {
        day: {
          totalIncidents: "878",
          occupancy: "45%",
          sanitationLevel: "54%",
          avgWaitTime: "54",
          operationalAlert: "570",
          occupancyRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          sanitationLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          avgWaitTimeData: [
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          airQualityIndexDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          operationalAlertsDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
          ],
          incidentsRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
        },
        weekly: {
          totalIncidents: "8278",
          occupancy: "45%",
          sanitationLevel: "48%",
          avgWaitTime: "61",
          operationalAlert: "1770",
          occupancyRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          sanitationLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          avgWaitTimeData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          airQualityIndexDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          operationalAlertsDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          incidentsRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
        },
        monthly: {
          totalIncidents: "28278",
          occupancy: "52%",
          sanitationLevel: "67%",
          avgWaitTime: "54",
          operationalAlert: "2170",
          occupancyRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          sanitationLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          avgWaitTimeData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          airQualityIndexDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          operationalAlertsDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 56,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          incidentsRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 46,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
        },
        yearly: {
          totalIncidents: "78278",
          occupancy: "54%",
          sanitationLevel: "65%",
          avgWaitTime: "65",
          operationalAlert: "8770",
          occupancyRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          sanitationLevelData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          avgWaitTimeData: [
            {
              node: "2022-10-11T11:00:00",
              count: 96,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          airQualityIndexDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
          ],
          operationalAlertsDataRestroom: [
            {
              node: "2022-10-11T11:00:00",
              count: 76,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
          incidentsRestroomData: [
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 86,
            },
            {
              node: "2022-10-11T13:00:00",
              count: 47.0,
            },
          ],
        },
      },
    },
  ],
};
export default analyticsRestrooms;
