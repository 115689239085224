const analyticsOutdoors = {
  analyticsList: [
    {
      id: "overAll",
      title: "OVERALL ANALYTICS",
      data: {
        day: {
          totalIncidentsOutdoors: "1780",
          electricityConsumption: "25 KW",
          dataUsed: "20 GB",
          airQualityIndex: "70",
          pm10: "136µg/m³",
          electricityConsumptionData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 84.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 72.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          dataUsedData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 35.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          airQualityIndexData: [
            {
              node: "2022-10-11T11:00:00",
              count: 167.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 137.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 95.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 167.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 185.0,
            },
          ],
          pm10Data: [
            {
              metricName: "pm2.5",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 75.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
              ],
            },
            {
              metricName: "pm10",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 75.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
              ],
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsBreakdownOutdoor: [
            {
              title: "HIGH ELECTRICITY CONSUMPTION",
              value: 25,
            },
            {
              title: "WEAK SIGNAL",
              value: 30,
            },
            {
              title: "LOW BANDWIDTH",
              value: 20,
            },
            {
              title: "HAZARDOUS AQI",
              value: 25,
            },
          ],
        },
        weekly: {
          totalIncidentsOutdoors: "2780",
          electricityConsumption: "125 KW",
          dataUsed: "120 GB",
          airQualityIndex: "170",
          pm10: "136",
          electricityConsumptionData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 84.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 72.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          dataUsedData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 84.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 72.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          airQualityIndexData: [
            {
              node: "2022-10-11T11:00:00",
              count: 40,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 70,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 102,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 100,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 150,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 170,
            },
          ],
          pm10Data: [
            {
              metricName: "pm2.5",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
              ],
            },
            {
              metricName: "pm10",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
              ],
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 62.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 72.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 90.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
          ],
          incidentsBreakdownOutdoor: [
            {
              title: "HIGH ELECTRICITY CONSUMPTION",
              value: 17,
            },
            {
              title: "WEAK SIGNAL",
              value: 22,
            },
            {
              title: "LOW BANDWIDTH",
              value: 32,
            },
            {
              title: "HAZARDOUS AQI",
              value: 42,
            },
          ],
        },
        monthly: {
          totalIncidentsOutdoors: "12780",
          electricityConsumption: "225 KW",
          dataUsed: "420 GB",
          airQualityIndex: "570",
          pm10: "436",
          electricityConsumptionData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          dataUsedData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
          ],
          airQualityIndexData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 185.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 117.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 107.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 105.0,
            },
          ],
          pm10Data: [
            {
              metricName: "pm2.5",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 75.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
              ],
            },
            {
              metricName: "pm10",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
              ],
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsBreakdownOutdoor: [
            {
              title: "HIGH ELECTRICITY CONSUMPTION",
              value: 30,
            },
            {
              title: "WEAK SIGNAL",
              value: 30,
            },
            {
              title: "LOW BANDWIDTH",
              value: 20,
            },
            {
              title: "HAZARDOUS AQI",
              value: 20,
            },
          ],
        },
        yearly: {
          totalIncidentsOutdoors: "22780",
          electricityConsumption: "2225 KW",
          dataUsed: "2220 GB",
          airQualityIndex: "2370",
          pm10: "23136",
          electricityConsumptionData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
          ],
          dataUsedData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 95.0,
            },
          ],
          airQualityIndexData: [
            {
              node: "2022-10-11T11:00:00",
              count: 107.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 105.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          pm10Data: [
            {
              metricName: "pm2.5",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 75.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
              ],
            },
            {
              metricName: "pm10",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
              ],
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
          ],
          incidentsBreakdownOutdoor: [
            {
              title: "HIGH ELECTRICITY CONSUMPTION",
              value: 30,
            },
            {
              title: "WEAK SIGNAL",
              value: 30,
            },
            {
              title: "LOW BANDWIDTH",
              value: 15,
            },
            {
              title: "HAZARDOUS AQI",
              value: 25,
            },
          ],
        },
      },
    },
    {
      id: "lp#123",
      title: "LP#123",
      data: {
        day: {
          totalIncidentsOutdoors: "1180",
          electricityConsumption: "125 KW",
          dataUsed: "220 GB",
          airQualityIndex: "370",
          pm10: "136",
          electricityConsumptionData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 95.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 66.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          dataUsedData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          airQualityIndexData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 107.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 165.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          pm10Data: [
            {
              metricName: "pm2.5",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
              ],
            },
            {
              metricName: "pm10",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
              ],
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 60.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 60.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 60.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
          ],
          incidentsBreakdownOutdoor: [
            {
              title: "HIGH ELECTRICITY CONSUMPTION",
              value: 25,
            },
            {
              title: "WEAK SIGNAL",
              value: 25,
            },
            {
              title: "LOW BANDWIDTH",
              value: 25,
            },
            {
              title: "HAZARDOUS AQI",
              value: 25,
            },
          ],
        },
        weekly: {
          totalIncidentsOutdoors: "4780",
          electricityConsumption: "325 KW",
          dataUsed: "620 GB",
          airQualityIndex: "770",
          pm10: "736",
          electricityConsumptionData: [
            {
              node: "2022-10-11T11:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
          ],
          dataUsedData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          airQualityIndexData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 115.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 117.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 106.0,
            },
          ],
          pm10Data: [
            {
              metricName: "pm2.5",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 15.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 75.0,
                },
              ],
            },
            {
              metricName: "pm10",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 75.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 55.0,
                },
              ],
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 95.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsBreakdownOutdoor: [
            {
              title: "HIGH ELECTRICITY CONSUMPTION",
              value: 15,
            },
            {
              title: "WEAK SIGNAL",
              value: 15,
            },
            {
              title: "LOW BANDWIDTH",
              value: 40,
            },
            {
              title: "HAZARDOUS AQI",
              value: 30,
            },
          ],
        },
        monthly: {
          totalIncidentsOutdoors: "12380",
          electricityConsumption: "3425 KW",
          dataUsed: "4520 GB",
          airQualityIndex: "2370",
          pm10: "3136",
          electricityConsumptionData: [
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
          ],
          dataUsedData: [
            {
              node: "2022-10-11T11:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          airQualityIndexData: [
            {
              node: "2022-10-11T11:00:00",
              count: 107.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 105.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 185.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          pm10Data: [
            {
              metricName: "pm2.5",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
              ],
            },
            {
              metricName: "pm10",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 75.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
              ],
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsBreakdownOutdoor: [
            {
              title: "HIGH ELECTRICITY CONSUMPTION",
              value: 25,
            },
            {
              title: "WEAK SIGNAL",
              value: 30,
            },
            {
              title: "LOW BANDWIDTH",
              value: 30,
            },
            {
              title: "HAZARDOUS AQI",
              value: 15,
            },
          ],
        },
        yearly: {
          totalIncidentsOutdoors: "25780",
          electricityConsumption: "2325 KW",
          dataUsed: "4520 GB",
          airQualityIndex: "3270",
          pm10: "5136",
          electricityConsumptionData: [
            {
              node: "2022-10-11T11:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          dataUsedData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          airQualityIndexData: [
            {
              node: "2022-10-11T11:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          pm10Data: [
            {
              metricName: "pm2.5",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 75.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
              ],
            },
            {
              metricName: "pm10",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
              ],
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 27.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 35.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsBreakdownOutdoor: [
            {
              title: "HIGH ELECTRICITY CONSUMPTION",
              value: 45,
            },
            {
              title: "WEAK SIGNAL",
              value: 25,
            },
            {
              title: "LOW BANDWIDTH",
              value: 15,
            },
            {
              title: "HAZARDOUS AQI",
              value: 15,
            },
          ],
        },
      },
    },
    {
      id: "lp#124",
      title: "LP#124",
      data: {
        day: {
          totalIncidentsOutdoors: "1580",
          electricityConsumption: "225 KW",
          dataUsed: "420 GB",
          airQualityIndex: "370",
          pm10: "236",
          electricityConsumptionData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 95.0,
            },
          ],
          dataUsedData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          airQualityIndexData: [
            {
              node: "2022-10-11T11:00:00",
              count: 107.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 105.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 125.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 135.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          pm10Data: [
            {
              metricName: "pm2.5",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 75.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
              ],
            },
            {
              metricName: "pm10",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 75.0,
                },
              ],
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsBreakdownOutdoor: [
            {
              title: "HIGH ELECTRICITY CONSUMPTION",
              value: 25,
            },
            {
              title: "WEAK SIGNAL",
              value: 45,
            },
            {
              title: "LOW BANDWIDTH",
              value: 15,
            },
            {
              title: "HAZARDOUS AQI",
              value: 15,
            },
          ],
        },
        weekly: {
          totalIncidentsOutdoors: "24780",
          electricityConsumption: "3425 KW",
          dataUsed: "1420 GB",
          airQualityIndex: "1370",
          pm10: "1136",
          electricityConsumptionData: [
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
          ],
          dataUsedData: [
            {
              node: "2022-10-11T11:00:00",
              count: 27.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 35.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
          ],
          airQualityIndexData: [
            {
              node: "2022-10-11T11:00:00",
              count: 107.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 165.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 156.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 134.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 121.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          pm10Data: [
            {
              metricName: "pm2.5",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 75.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
              ],
            },
            {
              metricName: "pm10",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
              ],
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsBreakdownOutdoor: [
            {
              title: "HIGH ELECTRICITY CONSUMPTION",
              value: 30,
            },
            {
              title: "WEAK SIGNAL",
              value: 30,
            },
            {
              title: "LOW BANDWIDTH",
              value: 30,
            },
            {
              title: "HAZARDOUS AQI",
              value: 10,
            },
          ],
        },
        monthly: {
          totalIncidentsOutdoors: "18340",
          electricityConsumption: "2325 KW",
          dataUsed: "3420 GB",
          airQualityIndex: "4570",
          pm10: "2136",
          electricityConsumptionData: [
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
          ],
          dataUsedData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          airQualityIndexData: [
            {
              node: "2022-10-11T11:00:00",
              count: 107.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 124.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 145.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 155.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          pm10Data: [
            {
              metricName: "pm2.5",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 75.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
              ],
            },
            {
              metricName: "pm10",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
              ],
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsBreakdownOutdoor: [
            {
              title: "HIGH ELECTRICITY CONSUMPTION",
              value: 25,
            },
            {
              title: "WEAK SIGNAL",
              value: 30,
            },
            {
              title: "LOW BANDWIDTH",
              value: 25,
            },
            {
              title: "HAZARDOUS AQI",
              value: 20,
            },
          ],
        },
        yearly: {
          totalIncidentsOutdoors: "22780",
          electricityConsumption: "2325 KW",
          dataUsed: "4320 GB",
          airQualityIndex: "2370",
          pm10: "2136",
          electricityConsumptionData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
          ],
          dataUsedData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          airQualityIndexData: [
            {
              node: "2022-10-11T11:00:00",
              count: 102.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 104.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 154.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 132.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          pm10Data: [
            {
              metricName: "pm2.5",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 75.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
              ],
            },
            {
              metricName: "pm10",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 75.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
              ],
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
          ],
          incidentsBreakdownOutdoor: [
            {
              title: "HIGH ELECTRICITY CONSUMPTION",
              value: 35,
            },
            {
              title: "WEAK SIGNAL",
              value: 35,
            },
            {
              title: "LOW BANDWIDTH",
              value: 15,
            },
            {
              title: "HAZARDOUS AQI",
              value: 15,
            },
          ],
        },
      },
    },
    {
      id: "lp#125",
      title: "LP#125",
      data: {
        day: {
          totalIncidentsOutdoors: "1280",
          electricityConsumption: "325 KW",
          dataUsed: "420 GB",
          airQualityIndex: "470",
          pm10: "236",
          electricityConsumptionData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          dataUsedData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          airQualityIndexData: [
            {
              node: "2022-10-11T11:00:00",
              count: 107.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 102.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 110.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          pm10Data: [
            {
              metricName: "pm2.5",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 45.0,
                },
              ],
            },
            {
              metricName: "pm10",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
              ],
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsBreakdownOutdoor: [
            {
              title: "HIGH ELECTRICITY CONSUMPTION",
              value: 20,
            },
            {
              title: "WEAK SIGNAL",
              value: 20,
            },
            {
              title: "LOW BANDWIDTH",
              value: 20,
            },
            {
              title: "HAZARDOUS AQI",
              value: 40,
            },
          ],
        },
        weekly: {
          totalIncidentsOutdoors: "12880",
          electricityConsumption: "2225 KW",
          dataUsed: "2320 GB",
          airQualityIndex: "3270",
          pm10: "2136",
          electricityConsumptionData: [
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          dataUsedData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 95.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          airQualityIndexData: [
            {
              node: "2022-10-11T11:00:00",
              count: 107.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 135.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 117.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 135.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 167.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 135.0,
            },
          ],
          pm10Data: [
            {
              metricName: "pm2.5",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },

                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
              ],
            },
            {
              metricName: "pm10",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 95.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
              ],
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsBreakdownOutdoor: [
            {
              title: "HIGH ELECTRICITY CONSUMPTION",
              value: 24,
            },
            {
              title: "WEAK SIGNAL",
              value: 36,
            },
            {
              title: "LOW BANDWIDTH",
              value: 20,
            },
            {
              title: "HAZARDOUS AQI",
              value: 20,
            },
          ],
        },
        monthly: {
          totalIncidentsOutdoors: "22380",
          electricityConsumption: "2325 KW",
          dataUsed: "3420 GB",
          airQualityIndex: "2470",
          pm10: "3136",
          electricityConsumptionData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          dataUsedData: [
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 35.0,
            },
          ],
          airQualityIndexData: [
            {
              node: "2022-10-11T11:00:00",
              count: 103.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 122.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 143.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 122.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 163.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 122.0,
            },
          ],
          pm10Data: [
            {
              metricName: "pm2.5",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 75.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
              ],
            },
            {
              metricName: "pm10",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 45.0,
                },
              ],
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsBreakdownOutdoor: [
            {
              title: "HIGH ELECTRICITY CONSUMPTION",
              value: 30,
            },
            {
              title: "WEAK SIGNAL",
              value: 15,
            },
            {
              title: "LOW BANDWIDTH",
              value: 15,
            },
            {
              title: "HAZARDOUS AQI",
              value: 40,
            },
          ],
        },
        yearly: {
          totalIncidentsOutdoors: "34780",
          electricityConsumption: "2425 KW",
          dataUsed: "4320 GB",
          airQualityIndex: "2370",
          pm10: "4136",
          electricityConsumptionData: [
            {
              node: "2022-10-11T11:00:00",
              count: 63.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 63.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 63.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          dataUsedData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          airQualityIndexData: [
            {
              node: "2022-10-11T11:00:00",
              count: 103.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 115.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 143.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 115.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 153.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 135.0,
            },
          ],
          pm10Data: [
            {
              metricName: "pm2.5",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
              ],
            },
            {
              metricName: "pm10",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 55.0,
                },
              ],
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsBreakdownOutdoor: [
            {
              title: "HIGH ELECTRICITY CONSUMPTION",
              value: 25,
            },
            {
              title: "WEAK SIGNAL",
              value: 35,
            },
            {
              title: "LOW BANDWIDTH",
              value: 15,
            },
            {
              title: "HAZARDOUS AQI",
              value: 15,
            },
          ],
        },
      },
    },
    {
      id: "lp#126",
      title: "LP#126",
      data: {
        day: {
          totalIncidentsOutdoors: "1280",
          electricityConsumption: "325 KW",
          dataUsed: "320 GB",
          airQualityIndex: "370",
          pm10: "236",
          electricityConsumptionData: [
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
          ],
          dataUsedData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          airQualityIndexData: [
            {
              node: "2022-10-11T11:00:00",
              count: 103.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 135.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 133.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 145.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 163.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 145.0,
            },
          ],
          pm10Data: [
            {
              metricName: "pm2.5",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 65.0,
                },
              ],
            },
            {
              metricName: "pm10",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
              ],
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsBreakdownOutdoor: [
            {
              title: "HIGH ELECTRICITY CONSUMPTION",
              value: 35,
            },
            {
              title: "WEAK SIGNAL",
              value: 15,
            },
            {
              title: "LOW BANDWIDTH",
              value: 25,
            },
            {
              title: "HAZARDOUS AQI",
              value: 25,
            },
          ],
        },
        weekly: {
          totalIncidentsOutdoors: "12680",
          electricityConsumption: "1225 KW",
          dataUsed: "3420 GB",
          airQualityIndex: "2570",
          pm10: "3136",
          electricityConsumptionData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
          ],
          dataUsedData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          airQualityIndexData: [
            {
              node: "2022-10-11T11:00:00",
              count: 117.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 125.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 127.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 125.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 117.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 155.0,
            },
          ],
          pm10Data: [
            {
              metricName: "pm2.5",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
              ],
            },
            {
              metricName: "pm10",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 35.0,
                },
              ],
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsBreakdownOutdoor: [
            {
              title: "HIGH ELECTRICITY CONSUMPTION",
              value: 30,
            },
            {
              title: "WEAK SIGNAL",
              value: 25,
            },
            {
              title: "LOW BANDWIDTH",
              value: 25,
            },
            {
              title: "HAZARDOUS AQI",
              value: 20,
            },
          ],
        },
        monthly: {
          totalIncidentsOutdoors: "22780",
          electricityConsumption: "2225 KW",
          dataUsed: "2320 GB",
          airQualityIndex: "3470",
          pm10: "4136",
          electricityConsumptionData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 53.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
          ],
          dataUsedData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          airQualityIndexData: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 105.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 135.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 149.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 105.0,
            },
          ],
          pm10Data: [
            {
              metricName: "pm2.5",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 53.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 53.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 53.0,
                },
              ],
            },
            {
              metricName: "pm10",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 55.0,
                },
              ],
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
          ],
          incidentsBreakdownOutdoor: [
            {
              title: "HIGH ELECTRICITY CONSUMPTION",
              value: 13,
            },
            {
              title: "WEAK SIGNAL",
              value: 27,
            },
            {
              title: "LOW BANDWIDTH",
              value: 28,
            },
            {
              title: "HAZARDOUS AQI",
              value: 32,
            },
          ],
        },
        yearly: {
          totalIncidentsOutdoors: "43780",
          electricityConsumption: "3425 KW",
          dataUsed: "5420 GB",
          airQualityIndex: "6470",
          pm10: "5136",
          electricityConsumptionData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
          ],
          dataUsedData: [
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
          ],
          airQualityIndexData: [
            {
              node: "2022-10-11T11:00:00",
              count: 107.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 124.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 147.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 124.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 137.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 124.0,
            },
          ],
          pm10Data: [
            {
              metricName: "pm2.5",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 65.0,
                },
              ],
            },
            {
              metricName: "pm10",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 95.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 95.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 95.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 65.0,
                },
              ],
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
          ],
          incidentsBreakdownOutdoor: [
            {
              title: "HIGH ELECTRICITY CONSUMPTION",
              value: 27,
            },
            {
              title: "WEAK SIGNAL",
              value: 33,
            },
            {
              title: "LOW BANDWIDTH",
              value: 25,
            },
            {
              title: "HAZARDOUS AQI",
              value: 15,
            },
          ],
        },
      },
    },
    {
      id: "lp#127",
      title: "LP#127",
      data: {
        day: {
          totalIncidentsOutdoors: "1980",
          electricityConsumption: "225 KW",
          dataUsed: "720 GB",
          airQualityIndex: "570",
          pm10: "436",
          electricityConsumptionData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
          ],
          dataUsedData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          airQualityIndexData: [
            {
              node: "2022-10-11T11:00:00",
              count: 104.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 105.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 134.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 145.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 104.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 165.0,
            },
          ],
          pm10Data: [
            {
              metricName: "pm2.5",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 75.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 75.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
              ],
            },
            {
              metricName: "pm10",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 48.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 68.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
              ],
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
          ],
          incidentsBreakdownOutdoor: [
            {
              title: "HIGH ELECTRICITY CONSUMPTION",
              value: 45,
            },
            {
              title: "WEAK SIGNAL",
              value: 15,
            },
            {
              title: "LOW BANDWIDTH",
              value: 25,
            },
            {
              title: "HAZARDOUS AQI",
              value: 15,
            },
          ],
        },
        weekly: {
          totalIncidentsOutdoors: "13980",
          electricityConsumption: "325 KW",
          dataUsed: "2120 GB",
          airQualityIndex: "2470",
          pm10: "2136",
          electricityConsumptionData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          dataUsedData: [
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          airQualityIndexData: [
            {
              node: "2022-10-11T11:00:00",
              count: 107.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 145.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 127.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 165.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 107.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 145.0,
            },
          ],
          pm10Data: [
            {
              metricName: "pm2.5",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 6.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
              ],
            },
            {
              metricName: "pm10",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 75.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 95.0,
                },
              ],
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsBreakdownOutdoor: [
            {
              title: "HIGH ELECTRICITY CONSUMPTION",
              value: 25,
            },
            {
              title: "WEAK SIGNAL",
              value: 35,
            },
            {
              title: "LOW BANDWIDTH",
              value: 20,
            },
            {
              title: "HAZARDOUS AQI",
              value: 20,
            },
          ],
        },
        monthly: {
          totalIncidentsOutdoors: "25780",
          electricityConsumption: "1425 KW",
          dataUsed: "2320 GB",
          airQualityIndex: "2470",
          pm10: "3136",
          electricityConsumptionData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 95.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          dataUsedData: [
            {
              node: "2022-10-11T11:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 95.0,
            },
          ],
          airQualityIndexData: [
            {
              node: "2022-10-11T11:00:00",
              count: 104.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 135.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 14.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 165.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 174.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 115.0,
            },
          ],
          pm10Data: [
            {
              metricName: "pm2.5",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 75.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 75.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 75.0,
                },
              ],
            },
            {
              metricName: "pm10",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
              ],
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsBreakdownOutdoor: [
            {
              title: "HIGH ELECTRICITY CONSUMPTION",
              value: 50,
            },
            {
              title: "WEAK SIGNAL",
              value: 20,
            },
            {
              title: "LOW BANDWIDTH",
              value: 15,
            },
            {
              title: "HAZARDOUS AQI",
              value: 15,
            },
          ],
        },
        yearly: {
          totalIncidentsOutdoors: "34980",
          electricityConsumption: "2325 KW",
          dataUsed: "3420 GB",
          airQualityIndex: "2370",
          pm10: "3136",
          electricityConsumptionData: [
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
          ],
          dataUsedData: [
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
          ],
          airQualityIndexData: [
            {
              node: "2022-10-11T11:00:00",
              count: 127.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 145.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 127.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 175.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 127.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 145.0,
            },
          ],
          pm10Data: [
            {
              metricName: "pm2.5",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 75.0,
                },
              ],
            },
            {
              metricName: "pm10",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 75.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
              ],
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 43.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 43.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 43.0,
            },
          ],
          incidentsBreakdownOutdoor: [
            {
              title: "HIGH ELECTRICITY CONSUMPTION",
              value: 30,
            },
            {
              title: "WEAK SIGNAL",
              value: 30,
            },
            {
              title: "LOW BANDWIDTH",
              value: 30,
            },
            {
              title: "HAZARDOUS AQI",
              value: 10,
            },
          ],
        },
      },
    },
    {
      id: "lp#128",
      title: "LP#128",
      data: {
        day: {
          totalIncidentsOutdoors: "1490",
          electricityConsumption: "325 KW",
          dataUsed: "320 GB",
          airQualityIndex: "470",
          pm10: "336",
          electricityConsumptionData: [
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
          ],
          dataUsedData: [
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 95.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 95.0,
            },
          ],
          airQualityIndexData: [
            {
              node: "2022-10-11T11:00:00",
              count: 107.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 105.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 127.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 135.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 157.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 105.0,
            },
          ],
          pm10Data: [
            {
              metricName: "pm2.5",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
              ],
            },
            {
              metricName: "pm10",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
              ],
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsBreakdownOutdoor: [
            {
              title: "HIGH ELECTRICITY CONSUMPTION",
              value: 15,
            },
            {
              title: "WEAK SIGNAL",
              value: 15,
            },
            {
              title: "LOW BANDWIDTH",
              value: 30,
            },
            {
              title: "HAZARDOUS AQI",
              value: 40,
            },
          ],
        },
        weekly: {
          totalIncidentsOutdoors: "17980",
          electricityConsumption: "3225 KW",
          dataUsed: "2320 GB",
          airQualityIndex: "3470",
          pm10: "2136",
          electricityConsumptionData: [
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 45.0,
            },
          ],
          dataUsedData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          airQualityIndexData: [
            {
              node: "2022-10-11T11:00:00",
              count: 107.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 125.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 167.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 125.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 157.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 125.0,
            },
          ],
          pm10Data: [
            {
              metricName: "pm2.5",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
              ],
            },
            {
              metricName: "pm10",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 55.0,
                },
              ],
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsBreakdownOutdoor: [
            {
              title: "HIGH ELECTRICITY CONSUMPTION",
              value: 25,
            },
            {
              title: "WEAK SIGNAL",
              value: 35,
            },
            {
              title: "LOW BANDWIDTH",
              value: 15,
            },
            {
              title: "HAZARDOUS AQI",
              value: 25,
            },
          ],
        },
        monthly: {
          totalIncidentsOutdoors: "14680",
          electricityConsumption: "2425 KW",
          dataUsed: "3420 GB",
          airQualityIndex: "4570",
          pm10: "3136",
          electricityConsumptionData: [
            {
              node: "2022-10-11T11:00:00",
              count: 74.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 74.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 74.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          dataUsedData: [
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          airQualityIndexData: [
            {
              node: "2022-10-11T11:00:00",
              count: 103.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 106.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 103.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 172.0,
            },

            {
              node: "2022-10-11T11:00:00",
              count: 103.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 101.0,
            },
          ],
          pm10Data: [
            {
              metricName: "pm2.5",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 65.0,
                },
              ],
            },
            {
              metricName: "pm10",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
              ],
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 5.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 5.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 5.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsBreakdownOutdoor: [
            {
              title: "HIGH ELECTRICITY CONSUMPTION",
              value: 30,
            },
            {
              title: "WEAK SIGNAL",
              value: 25,
            },
            {
              title: "LOW BANDWIDTH",
              value: 15,
            },
            {
              title: "HAZARDOUS AQI",
              value: 30,
            },
          ],
        },
        yearly: {
          totalIncidentsOutdoors: "42780",
          electricityConsumption: "3425 KW",
          dataUsed: "6520 GB",
          airQualityIndex: "7670",
          pm10: "4136",
          electricityConsumptionData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          dataUsedData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          airQualityIndexData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 103.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 105.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 127.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 145.0,
            },
          ],
          pm10Data: [
            {
              metricName: "pm2.5",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 65.0,
                },
              ],
            },
            {
              metricName: "pm10",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
              ],
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 95.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 95.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 95.0,
            },
          ],
          incidentsBreakdownOutdoor: [
            {
              title: "HIGH ELECTRICITY CONSUMPTION",
              value: 25,
            },
            {
              title: "WEAK SIGNAL",
              value: 25,
            },
            {
              title: "LOW BANDWIDTH",
              value: 25,
            },
            {
              title: "HAZARDOUS AQI",
              value: 25,
            },
          ],
        },
      },
    },
    {
      id: "lp#129",
      title: "LP#129",
      data: {
        day: {
          totalIncidentsOutdoors: "2280",
          electricityConsumption: "325 KW",
          dataUsed: "420 GB",
          airQualityIndex: "470",
          pm10: "536",
          electricityConsumptionData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 95.0,
            },
          ],
          dataUsedData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          airQualityIndexData: [
            {
              node: "2022-10-11T11:00:00",
              count: 127.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 155.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 127.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 165.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 167.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 155.0,
            },
          ],
          pm10Data: [
            {
              metricName: "pm2.5",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 56.0,
                },
              ],
            },
            {
              metricName: "pm10",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 75.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 75.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 75.0,
                },
              ],
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsBreakdownOutdoor: [
            {
              title: "HIGH ELECTRICITY CONSUMPTION",
              value: 20,
            },
            {
              title: "WEAK SIGNAL",
              value: 20,
            },
            {
              title: "LOW BANDWIDTH",
              value: 30,
            },
            {
              title: "HAZARDOUS AQI",
              value: 30,
            },
          ],
        },
        weekly: {
          totalIncidentsOutdoors: "22780",
          electricityConsumption: "2325 KW",
          dataUsed: "2620 GB",
          airQualityIndex: "2870",
          pm10: "2136",
          electricityConsumptionData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          dataUsedData: [
            {
              node: "2022-10-11T11:00:00",
              count: 33.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 62.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 33.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 62.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 33.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 62.0,
            },
          ],
          airQualityIndexData: [
            {
              node: "2022-10-11T11:00:00",
              count: 147.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 165.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 147.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 125.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 187.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 165.0,
            },
          ],
          pm10Data: [
            {
              metricName: "pm2.5",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 75.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 35.0,
                },
              ],
            },
            {
              metricName: "pm10",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 55.0,
                },
              ],
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
          ],
          incidentsBreakdownOutdoor: [
            {
              title: "HIGH ELECTRICITY CONSUMPTION",
              value: 35,
            },
            {
              title: "WEAK SIGNAL",
              value: 15,
            },
            {
              title: "LOW BANDWIDTH",
              value: 30,
            },
            {
              title: "HAZARDOUS AQI",
              value: 20,
            },
          ],
        },
        monthly: {
          totalIncidentsOutdoors: "32780",
          electricityConsumption: "2325 KW",
          dataUsed: "12520 GB",
          airQualityIndex: "2370",
          pm10: "5136",
          electricityConsumptionData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
          ],
          dataUsedData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 54.0,
            },
          ],
          airQualityIndexData: [
            {
              node: "2022-10-11T11:00:00",
              count: 127.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 145.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 157.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 165.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 177.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 145.0,
            },
          ],
          pm10Data: [
            {
              metricName: "pm2.5",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
              ],
            },
            {
              metricName: "pm10",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
              ],
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
          ],
          incidentsBreakdownOutdoor: [
            {
              title: "HIGH ELECTRICITY CONSUMPTION",
              value: 30,
            },
            {
              title: "WEAK SIGNAL",
              value: 25,
            },
            {
              title: "LOW BANDWIDTH",
              value: 30,
            },
            {
              title: "HAZARDOUS AQI",
              value: 15,
            },
          ],
        },
        yearly: {
          totalIncidentsOutdoors: "42780",
          electricityConsumption: "2325 KW",
          dataUsed: "21120 GB",
          airQualityIndex: "3270",
          pm10: "3136",
          electricityConsumptionData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          dataUsedData: [
            {
              node: "2022-10-11T11:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 95.0,
            },
          ],
          airQualityIndexData: [
            {
              node: "2022-10-11T11:00:00",
              count: 127.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 185.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 137.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 175.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 127.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 185.0,
            },
          ],
          pm10Data: [
            {
              metricName: "pm2.5",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 65.0,
                },
              ],
            },
            {
              metricName: "pm10",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 95.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 95.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 95.0,
                },
              ],
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
          ],
          incidentsBreakdownOutdoor: [
            {
              title: "HIGH ELECTRICITY CONSUMPTION",
              value: 15,
            },
            {
              title: "WEAK SIGNAL",
              value: 15,
            },
            {
              title: "LOW BANDWIDTH",
              value: 15,
            },
            {
              title: "HAZARDOUS AQI",
              value: 55,
            },
          ],
        },
      },
    },
    {
      id: "lp#130",
      title: "LP#130",
      data: {
        day: {
          totalIncidentsOutdoors: "1480",
          electricityConsumption: "125 KW",
          dataUsed: "320 GB",
          airQualityIndex: "670",
          pm10: "436",
          electricityConsumptionData: [
            {
              node: "2022-10-11T11:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 0.5,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 65.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          dataUsedData: [
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 62.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          airQualityIndexData: [
            {
              node: "2022-10-11T11:00:00",
              count: 112.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 125.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 117.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 125.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 162.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 125.0,
            },
          ],
          pm10Data: [
            {
              metricName: "pm2.5",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
              ],
            },
            {
              metricName: "pm10",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
              ],
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsBreakdownOutdoor: [
            {
              title: "HIGH ELECTRICITY CONSUMPTION",
              value: 25,
            },
            {
              title: "WEAK SIGNAL",
              value: 35,
            },
            {
              title: "LOW BANDWIDTH",
              value: 15,
            },
            {
              title: "HAZARDOUS AQI",
              value: 25,
            },
          ],
        },
        weekly: {
          totalIncidentsOutdoors: "22580",
          electricityConsumption: "2125 KW",
          dataUsed: "2320 GB",
          airQualityIndex: "2470",
          pm10: "2136",
          electricityConsumptionData: [
            {
              node: "2022-10-11T11:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 55.0,
            },
          ],
          dataUsedData: [
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          airQualityIndexData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 197.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 125.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          pm10Data: [
            {
              metricName: "pm2.5",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
              ],
            },
            {
              metricName: "pm10",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
              ],
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 76.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 56.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 56.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 56.0,
            },
          ],
          incidentsBreakdownOutdoor: [
            {
              title: "HIGH ELECTRICITY CONSUMPTION",
              value: 26,
            },
            {
              title: "WEAK SIGNAL",
              value: 24,
            },
            {
              title: "LOW BANDWIDTH",
              value: 30,
            },
            {
              title: "HAZARDOUS AQI",
              value: 20,
            },
          ],
        },
        monthly: {
          totalIncidentsOutdoors: "22980",
          electricityConsumption: "2325 KW",
          dataUsed: "2320 GB",
          airQualityIndex: "2370",
          pm10: "2136",
          electricityConsumptionData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          dataUsedData: [
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 35.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 35.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 35.0,
            },
          ],
          airQualityIndexData: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 107.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 125.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 185.0,
            },
          ],
          pm10Data: [
            {
              metricName: "pm2.5",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 95.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 95.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 95.0,
                },
              ],
            },
            {
              metricName: "pm10",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
              ],
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 75.0,
            },
          ],
          incidentsBreakdownOutdoor: [
            {
              title: "HIGH ELECTRICITY CONSUMPTION",
              value: 30,
            },
            {
              title: "WEAK SIGNAL",
              value: 30,
            },
            {
              title: "LOW BANDWIDTH",
              value: 25,
            },
            {
              title: "HAZARDOUS AQI",
              value: 15,
            },
          ],
        },
        yearly: {
          totalIncidentsOutdoors: "42780",
          electricityConsumption: "2325 KW",
          dataUsed: "2520 GB",
          airQualityIndex: "2470",
          pm10: "3136",
          electricityConsumptionData: [
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          dataUsedData: [
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          airQualityIndexData: [
            {
              node: "2022-10-11T11:00:00",
              count: 107.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 135.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 176.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 135.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 187.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 135.0,
            },
          ],
          pm10Data: [
            {
              metricName: "pm2.5",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
              ],
            },
            {
              metricName: "pm10",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
              ],
            },
          ],
          incidentsData: [
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 85.0,
            },
          ],
          incidentsBreakdownOutdoor: [
            {
              title: "HIGH ELECTRICITY CONSUMPTION",
              value: 30,
            },
            {
              title: "WEAK SIGNAL",
              value: 25,
            },
            {
              title: "LOW BANDWIDTH",
              value: 25,
            },
            {
              title: "HAZARDOUS AQI",
              value: 20,
            },
          ],
        },
      },
    },
  ],
};
export default analyticsOutdoors;
