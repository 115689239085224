const analyticsRestaurants = {
  analyticsList: [
    {
      id: "overAll",
      title: "OVERALL ANALYTICS",
      data: {
        day: {
          totalOrder: "400",
          income: "$123K",
          totalVisitors: "400",
          reservation: "125",
          incident: "40",
          order: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
              ],
            },
          ],
          reservations: [
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          visitor: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
          ],
          occupancyRestaurant: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          revenue: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
          revenueBreakdown: [
            {
              id: "food",
              title: "Food",
              value: 30,
            },
            {
              id: "snacks",
              title: "Snacks",
              value: 40,
            },
            {
              id: "drinks",
              title: "Drinks",
              value: 30,
            },
          ],
        },
        weekly: {
          totalOrder: "600",
          income: "$723K",
          totalVisitors: "700",
          reservation: "125",
          incident: "39",
          order: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 127,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 127,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 127,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 55,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 137.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 137.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 137.0,
                },
              ],
            },
          ],
          reservations: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 88.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 66.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 70.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 80.0,
            },
          ],
          visitor: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          occupancyRestaurant: [
            {
              node: "2022-10-11T11:00:00",
              count: 80.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 28.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 27.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          revenue: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 36.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
              ],
            },
          ],
          revenueBreakdown: [
            {
              id: "food",
              title: "Food",
              value: 50,
            },
            {
              id: "snacks",
              title: "Snacks",
              value: 20,
            },
            {
              id: "drinks",
              title: "Drinks",
              value: 38,
            },
          ],
        },
        monthly: {
          totalOrder: "900",
          income: "$923K",
          totalVisitors: "900",
          reservation: "325",
          incident: "60",
          order: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
          reservations: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          visitor: [
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
          ],
          occupancyRestaurant: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          revenue: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 9,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
          revenueBreakdown: [
            {
              id: "food",
              title: "Food",
              value: 50,
            },
            {
              id: "snacks",
              title: "Snacks",
              value: 20,
            },
            {
              id: "drinks",
              title: "Drinks",
              value: 30,
            },
          ],
        },
        yearly: {
          totalOrder: "2400",
          income: "$2923K",
          totalVisitors: "12200",
          reservation: "3925",
          incident: "13200",
          order: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 67,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
              ],
            },
          ],
          reservations: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 59.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
          ],
          visitor: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 63.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 54.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          occupancyRestaurant: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 29.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          revenue: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 16,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 37.0,
                },
              ],
            },
          ],
          revenueBreakdown: [
            {
              id: "food",
              title: "Food",
              value: 40,
            },
            {
              id: "snacks",
              title: "Snacks",
              value: 10,
            },
            {
              id: "drinks",
              title: "Drinks",
              value: 40,
            },
          ],
        },
      },
    },
    {
      id: "metropolitanGrille",
      title: "The Metropolitan Grille",
      data: {
        day: {
          totalOrder: "500",
          income: "$323K",
          totalVisitors: "400",
          reservation: "425",
          incident: "130",
          order: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 37.0,
                },
              ],
            },
          ],
          reservations: [
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          visitor: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 59.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          occupancyRestaurant: [
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          revenue: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
          revenueBreakdown: [
            {
              id: "food",
              title: "Food",
              value: 50,
            },
            {
              id: "snacks",
              title: "Snacks",
              value: 10,
            },
            {
              id: "drinks",
              title: "Drinks",
              value: 39,
            },
          ],
        },
        weekly: {
          totalOrder: "700",
          income: "$523K",
          totalVisitors: "900",
          reservation: "425",
          incident: "130",
          order: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
          reservations: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 27.0,
            },
          ],
          visitor: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 27.0,
            },
          ],
          occupancyRestaurant: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          revenue: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
          revenueBreakdown: [
            {
              id: "food",
              title: "Food",
              value: 40,
            },
            {
              id: "snacks",
              title: "Snacks",
              value: 30,
            },
            {
              id: "drinks",
              title: "Drinks",
              value: 30,
            },
          ],
        },
        monthly: {
          totalOrder: "900",
          income: "$923K",
          totalVisitors: "800",
          reservation: "725",
          incident: "94",
          order: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
          reservations: [
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 78.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          visitor: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 27.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          occupancyRestaurant: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          revenue: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 37.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 27.0,
                },
              ],
            },
          ],
          revenueBreakdown: [
            {
              id: "food",
              title: "Food",
              value: 30,
            },
            {
              id: "snacks",
              title: "Snacks",
              value: 20,
            },
            {
              id: "drinks",
              title: "Drinks",
              value: 40,
            },
          ],
        },
        yearly: {
          totalOrder: "6500",
          income: "$8623K",
          totalVisitors: "1300",
          reservation: "825",
          incident: "399",
          order: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
              ],
            },
          ],
          reservations: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
          ],
          visitor: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          occupancyRestaurant: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
          ],
          revenue: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 67.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
          revenueBreakdown: [
            {
              id: "food",
              title: "Food",
              value: 30,
            },
            {
              id: "snacks",
              title: "Snacks",
              value: 40,
            },
            {
              id: "drinks",
              title: "Drinks",
              value: 30,
            },
          ],
        },
      },
    },
    {
      id: "cloverHome",
      title: "Clover Home Plate Club",
      data: {
        day: {
          totalOrder: "800",
          income: "$723K",
          totalVisitors: "800",
          reservation: "725",
          incident: "379",
          order: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
              ],
            },
          ],
          reservations: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 43.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
          ],
          visitor: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 37.0,
            },
          ],
          occupancyRestaurant: [
            {
              node: "2022-10-11T11:00:00",
              count: 80.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
          ],
          revenue: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 37.0,
                },
              ],
            },
          ],
          revenueBreakdown: [
            {
              id: "food",
              title: "Food",
              value: 20,
            },
            {
              id: "snacks",
              title: "Snacks",
              value: 50,
            },
            {
              id: "drinks",
              title: "Drinks",
              value: 30,
            },
          ],
        },
        weekly: {
          totalOrder: "900",
          income: "$923K",
          totalVisitors: "1200",
          reservation: "1250",
          incident: "390",
          order: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
              ],
            },
          ],
          reservations: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 27.0,
            },
          ],
          visitor: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
          ],
          occupancyRestaurant: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 97.0,
            },
          ],
          revenue: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96.0,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },

                {
                  node: "2022-10-11T11:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
          revenueBreakdown: [
            {
              id: "food",
              title: "Food",
              value: 50,
            },
            {
              id: "snacks",
              title: "Snacks",
              value: 30,
            },
            {
              id: "drinks",
              title: "Drinks",
              value: 30,
            },
          ],
        },
        monthly: {
          totalOrder: "1200",
          income: "$7123K",
          totalVisitors: "800",
          reservation: "1275",
          incident: "369",
          order: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
              ],
            },
          ],
          reservations: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 37.0,
            },
          ],
          visitor: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
          ],
          occupancyRestaurant: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
          ],
          revenue: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",

                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
              ],
            },
          ],
          revenueBreakdown: [
            {
              id: "food",
              title: "Food",
              value: 30,
            },
            {
              id: "snacks",
              title: "Snacks",
              value: 40,
            },
            {
              id: "drinks",
              title: "Drinks",
              value: 38,
            },
          ],
        },
        yearly: {
          totalOrder: "6800",
          income: "$7243K",
          totalVisitors: "7090",
          reservation: "1285",
          incident: "839",
          order: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
              ],
            },
          ],
          reservations: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          visitor: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 27.0,
            },
          ],
          occupancyRestaurant: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          revenue: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 37.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
          revenueBreakdown: [
            {
              id: "food",
              title: "Food",
              value: 40,
            },
            {
              id: "snacks",
              title: "Snacks",
              value: 40,
            },
            {
              id: "drinks",
              title: "Drinks",
              value: 38,
            },
          ],
        },
      },
    },
    {
      id: "coors",
      title: "Coors Light Skyline",
      data: {
        day: {
          totalOrder: "500",
          income: "$123K",
          totalVisitors: "200",
          reservation: "725",
          incident: "139",
          order: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 37.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
          reservations: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 37.0,
            },
          ],
          visitor: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 17.0,
            },
          ],
          occupancyRestaurant: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 59.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          revenue: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 27.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 60,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 67.0,
                },
              ],
            },
          ],
          revenueBreakdown: [
            {
              id: "food",
              title: "Food",
              value: 10,
            },
            {
              id: "snacks",
              title: "Snacks",
              value: 60,
            },
            {
              id: "drinks",
              title: "Drinks",
              value: 30,
            },
          ],
        },
        weekly: {
          totalOrder: "1600",
          income: "$7233K",
          totalVisitors: "500",
          reservation: "4125",
          incident: "839",
          order: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
          reservations: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          visitor: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          occupancyRestaurant: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
          ],
          revenue: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
              ],
            },
          ],
          revenueBreakdown: [
            {
              id: "food",
              title: "Food",
              value: 50,
            },
            {
              id: "snacks",
              title: "Snacks",
              value: 22,
            },
            {
              id: "drinks",
              title: "Drinks",
              value: 28,
            },
          ],
        },
        monthly: {
          totalOrder: "7800",
          income: "$7273K",
          totalVisitors: "1700",
          reservation: "1125",
          incident: "379",
          order: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 37.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
              ],
            },
          ],
          reservations: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 17.0,
            },
          ],
          visitor: [
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
          ],
          occupancyRestaurant: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 49.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
          ],
          revenue: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 37.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
          revenueBreakdown: [
            {
              id: "food",
              title: "Food",
              value: 20,
            },
            {
              id: "snacks",
              title: "Snacks",
              value: 20,
            },
            {
              id: "drinks",
              title: "Drinks",
              value: 60,
            },
          ],
        },
        yearly: {
          totalOrder: "5600",
          income: "$7273K",
          totalVisitors: "8700",
          reservation: "9125",
          incident: "3879",
          order: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
          reservations: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 27.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          visitor: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 27.0,
            },
          ],
          occupancyRestaurant: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
          ],
          revenue: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
          revenueBreakdown: [
            {
              id: "food",
              title: "Food",
              value: 60,
            },
            {
              id: "snacks",
              title: "Snacks",
              value: 20,
            },
            {
              id: "drinks",
              title: "Drinks",
              value: 20,
            },
          ],
        },
      },
    },
    {
      id: "delta",
      title: "Delta Sky360° Club",
      data: {
        day: {
          totalOrder: "800",
          income: "$523K",
          totalVisitors: "400",
          reservation: "1285",
          incident: "89",
          order: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 37.0,
                },
              ],
            },
          ],
          reservations: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 76.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 91.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 50.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          visitor: [
            {
              node: "2022-10-11T11:00:00",
              count: 90.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 42.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          occupancyRestaurant: [
            {
              node: "2022-10-11T11:00:00",
              count: 19.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 17.0,
            },
          ],
          revenue: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
              ],
            },
          ],
          revenueBreakdown: [
            {
              id: "food",
              title: "Food",
              value: 50,
            },
            {
              id: "snacks",
              title: "Snacks",
              value: 35,
            },
            {
              id: "drinks",
              title: "Drinks",
              value: 15,
            },
          ],
        },
        weekly: {
          totalOrder: "1400",
          income: "$1723K",
          totalVisitors: "800",
          reservation: "1725",
          incident: "398",
          order: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 67.0,
                },
              ],
            },
          ],
          reservations: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          visitor: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 96.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 17.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 80.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          occupancyRestaurant: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 76.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 36.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          revenue: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
              ],
            },
          ],
          revenueBreakdown: [
            {
              id: "food",
              title: "Food",
              value: 33,
            },
            {
              id: "snacks",
              title: "Snacks",
              value: 33,
            },
            {
              id: "drinks",
              title: "Drinks",
              value: 33,
            },
          ],
        },
        monthly: {
          totalOrder: "6700",
          income: "$7243K",
          totalVisitors: "4700",
          reservation: "1425",
          incident: "4439",
          order: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 147.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
              ],
            },
          ],
          reservations: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          visitor: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          occupancyRestaurant: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          revenue: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 67.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 70.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
              ],
            },
          ],
          revenueBreakdown: [
            {
              id: "food",
              title: "Food",
              value: 60,
            },
            {
              id: "snacks",
              title: "Snacks",
              value: 20,
            },
            {
              id: "drinks",
              title: "Drinks",
              value: 18,
            },
          ],
        },
        yearly: {
          totalOrder: "65600",
          income: "$72343K",
          totalVisitors: "73300",
          reservation: "12325",
          incident: "11339",
          order: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
              ],
            },
          ],
          reservations: [
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
          ],
          visitor: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          occupancyRestaurant: [
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
          ],
          revenue: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
              ],
            },
          ],
          revenueBreakdown: [
            {
              id: "food",
              title: "Food",
              value: 20,
            },
            {
              id: "snacks",
              title: "Snacks",
              value: 50,
            },
            {
              id: "drinks",
              title: "Drinks",
              value: 30,
            },
          ],
        },
      },
    },
    {
      id: "hyundaiClub",
      title: "Hyundai Club - First Base & Third Base",
      data: {
        day: {
          totalOrder: "700",
          income: "$823K",
          totalVisitors: "900",
          reservation: "8125",
          incident: "398",
          order: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
              ],
            },
          ],
          reservations: [
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 37.0,
            },
          ],
          visitor: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 97.0,
            },
          ],
          occupancyRestaurant: [
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          revenue: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
              ],
            },
          ],
          revenueBreakdown: [
            {
              id: "food",
              title: "Food",
              value: 50,
            },
            {
              id: "snacks",
              title: "Snacks",
              value: 25,
            },
            {
              id: "drinks",
              title: "Drinks",
              value: 25,
            },
          ],
        },
        weekly: {
          totalOrder: "8700",
          income: "$7323K",
          totalVisitors: "900",
          reservation: "1245",
          incident: "439",
          order: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 37.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 85,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
              ],
            },
          ],
          reservations: [
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          visitor: [
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
          ],
          occupancyRestaurant: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
          ],
          revenue: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
              ],
            },
          ],
          revenueBreakdown: [
            {
              id: "food",
              title: "Food",
              value: 40,
            },
            {
              id: "snacks",
              title: "Snacks",
              value: 40,
            },
            {
              id: "drinks",
              title: "Drinks",
              value: 20,
            },
          ],
        },
        monthly: {
          totalOrder: "7800",
          income: "$2723K",
          totalVisitors: "900",
          reservation: "3125",
          incident: "239",
          order: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 37.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },

                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 67.0,
                },
              ],
            },
          ],
          reservations: [
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
          ],
          visitor: [
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
          ],
          occupancyRestaurant: [
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
          ],
          revenue: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 37.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 56,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 75,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 75,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 37.0,
                },
              ],
            },
          ],
          revenueBreakdown: [
            {
              id: "food",
              title: "Food",
              value: 40,
            },
            {
              id: "snacks",
              title: "Snacks",
              value: 20,
            },
            {
              id: "drinks",
              title: "Drinks",
              value: 40,
            },
          ],
        },
        yearly: {
          totalOrder: "62200",
          income: "$7223K",
          totalVisitors: "9700",
          reservation: "9125",
          incident: "939",
          order: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 37.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 37.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
          reservations: [
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 97.0,
            },
          ],
          visitor: [
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          occupancyRestaurant: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
          ],
          revenue: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 69,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
              ],
            },
          ],
          revenueBreakdown: [
            {
              id: "food",
              title: "Food",
              value: 33,
            },
            {
              id: "snacks",
              title: "Snacks",
              value: 33,
            },
            {
              id: "drinks",
              title: "Drinks",
              value: 34,
            },
          ],
        },
      },
    },
    {
      id: "jimBeam",
      title: "Jim Beam Highball Club",
      data: {
        day: {
          totalOrder: "800",
          income: "$623K",
          totalVisitors: "800",
          reservation: "1125",
          incident: "139",
          order: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 67.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
              ],
            },
          ],
          reservations: [
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 37.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 37.0,
            },
          ],
          visitor: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          occupancyRestaurant: [
            {
              node: "2022-10-11T11:00:00",
              count: 94.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 94.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 94.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
          ],
          revenue: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 37.0,
                },
              ],
            },
          ],
          revenueBreakdown: [
            {
              id: "food",
              title: "Food",
              value: 45,
            },
            {
              id: "snacks",
              title: "Snacks",
              value: 15,
            },
            {
              id: "drinks",
              title: "Drinks",
              value: 40,
            },
          ],
        },
        weekly: {
          totalOrder: "9800",
          income: "$2723K",
          totalVisitors: "1700",
          reservation: "4125",
          incident: "239",
          order: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
              ],
            },
          ],
          reservations: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
          ],
          visitor: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          occupancyRestaurant: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
          ],
          revenue: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
              ],
            },
          ],
          revenueBreakdown: [
            {
              id: "food",
              title: "Food",
              value: 60,
            },
            {
              id: "snacks",
              title: "Snacks",
              value: 20,
            },
            {
              id: "drinks",
              title: "Drinks",
              value: 20,
            },
          ],
        },
        monthly: {
          totalOrder: "8600",
          income: "$4723K",
          totalVisitors: "1900",
          reservation: "8125",
          incident: "739",
          order: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
              ],
            },
          ],
          reservations: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          visitor: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
          ],
          occupancyRestaurant: [
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
          ],
          revenue: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 67.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
          revenueBreakdown: [
            {
              id: "food",
              title: "Food",
              value: 60,
            },
            {
              id: "snacks",
              title: "Snacks",
              value: 22,
            },
            {
              id: "drinks",
              title: "Drinks",
              value: 18,
            },
          ],
        },
        yearly: {
          totalOrder: "87600",
          income: "$12723K",
          totalVisitors: "9800",
          reservation: "9125",
          incident: "8139",
          order: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
              ],
            },
          ],
          reservations: [
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
          ],
          visitor: [
            {
              node: "2022-10-11T11:00:00",
              count: 90.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 90.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 90.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
          ],
          occupancyRestaurant: [
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          revenue: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 17.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
              ],
            },
          ],
          revenueBreakdown: [
            {
              id: "food",
              title: "Food",
              value: 40,
            },
            {
              id: "snacks",
              title: "Snacks",
              value: 40,
            },
            {
              id: "drinks",
              title: "Drinks",
              value: 20,
            },
          ],
        },
      },
    },
    {
      id: "piazza",
      title: "Piazza 31 Club",
      data: {
        day: {
          totalOrder: "800",
          income: "$1723K",
          totalVisitors: "800",
          reservation: "325",
          incident: "239",
          order: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
          reservations: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
          ],
          visitor: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 79.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
          ],
          occupancyRestaurant: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
          ],
          revenue: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 78.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
          revenueBreakdown: [
            {
              id: "food",
              title: "Food",
              value: 20,
            },
            {
              id: "snacks",
              title: "Snacks",
              value: 20,
            },
            {
              id: "drinks",
              title: "Drinks",
              value: 60,
            },
          ],
        },
        weekly: {
          totalOrder: "3400",
          income: "$2723K",
          totalVisitors: "900",
          reservation: "3125",
          incident: "239",
          order: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
          ],
          reservations: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 9.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
          ],
          visitor: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 90.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
          ],
          occupancyRestaurant: [
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 47.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 99.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
          ],
          revenue: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 37.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 67.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
              ],
            },
          ],
          revenueBreakdown: [
            {
              id: "food",
              title: "Food",
              value: 40,
            },
            {
              id: "snacks",
              title: "Snacks",
              value: 20,
            },
            {
              id: "drinks",
              title: "Drinks",
              value: 40,
            },
          ],
        },
        monthly: {
          totalOrder: "2600",
          income: "$7323K",
          totalVisitors: "800",
          reservation: "3125",
          incident: "379",
          order: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 57.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
              ],
            },
          ],
          reservations: [
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
          ],
          visitor: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 57.0,
            },
          ],
          occupancyRestaurant: [
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
          ],
          revenue: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 87.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 56,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
              ],
            },
          ],
          revenueBreakdown: [
            {
              id: "food",
              title: "Food",
              value: 70,
            },
            {
              id: "snacks",
              title: "Snacks",
              value: 20,
            },
            {
              id: "drinks",
              title: "Drinks",
              value: 10,
            },
          ],
        },
        yearly: {
          totalOrder: "23600",
          income: "$23723K",
          totalVisitors: "9300",
          reservation: "34125",
          incident: "3439",
          order: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 67.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 67.0,
                },
              ],
            },
          ],
          reservations: [
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 97.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 67.0,
            },
          ],
          visitor: [
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 89.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 77.0,
            },
          ],
          occupancyRestaurant: [
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
            {
              node: "2022-10-11T11:00:00",
              count: 69.0,
            },
            {
              node: "2022-10-11T12:00:00",
              count: 87.0,
            },
          ],
          revenue: [
            {
              metricName: "meal",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 47.0,
                },
              ],
            },
            {
              metricName: "snacks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 77.0,
                },
              ],
            },
            {
              metricName: "drinks",
              analytics: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 97.0,
                },
              ],
            },
          ],
          revenueBreakdown: [
            {
              id: "food",
              title: "Food",
              value: 32,
            },
            {
              id: "snacks",
              title: "Snacks",
              value: 37,
            },
            {
              id: "drinks",
              title: "Drinks",
              value: 30,
            },
          ],
        },
      },
    },
  ],
};
export default analyticsRestaurants;
